import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import swal from 'sweetalert';
import $ from "jquery";
import { withRouter ,Link} from 'react-router-dom';
import S3FileUpload from 'react-s3';
import "./userManagement.css";

class EditUserProfile extends Component {
	constructor(props) {
		super(props);
		var UserId = this.props.match.params.id;
		this.state = {
			UserId: UserId,
			fullname: "",
			username: "",
			mobNumber: "",
			profileImage: "",
			firstName: "",
			lastName: "",
			centerName: "",
		}
		this.handleChange = this.handleChange.bind(this);
	}
	handleSubmit(event) {
		event.preventDefault();
		if ($('#editUser').valid()) {
			var userid = this.state.UserId;

			var formvalues = {
				"firstname": this.refs.firstName.value,
				"lastname": this.refs.lastName.value,
				"mobNumber": this.state.mobNumber,
				"image": this.state.profileImage,
			}
			console.log("image formvalues==>", formvalues)
			axios.patch('/api/users/patch/' + userid, formvalues)
				.then((response) => {
						console.log('response',response);
					if (response.data="USER_UPDATED") {
						swal({
							title: " ",
							text: "User updated successfully",
						});
						this.props.history.push('/profile/'+this.state.UserId);
						window.location.reload();
                    }else{
					// updated : false
					swal({
							title: " ",
							text: "User not modified",
						});

                    }
				})
				.catch((error) => {});
		}
	}


	handleChange(event) {
		const target = event.target.value;
		const name = event.target.name;
		this.setState({
			[name]: target,
		}, () => {
		})
	}

	componentDidMount() {
		var userid = this.state.UserId;
		axios.get('/api/users/get/' + userid)
			.then((res) => {
				console.log("res.data.image==>", res);
				this.setState({
					firstName: res.data.firstname,
					lastName: res.data.lastname,
					username: res.data.email,
					mobNumber: res.data.mobile,
					profileImage: res.data.image,
					companyID: res.data.companyID
				})
			})
			.catch((error) => {
			});
	}
	imgBrowse(event) {
		event.preventDefault();
		var profileImage = "";
		if (event.currentTarget.files && event.currentTarget.files[0]) {
			var file = event.currentTarget.files[0];
			console.log("file==>", file);
			if (file) {
				var fileName = file.name;
				console.log("fileName==>", fileName);
				var ext = fileName.split('.').pop();
				if (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "JPG" || ext === "PNG" || ext === "JPEG") {
					if (file) {
						var objTitle = { fileInfo: file }
						profileImage = objTitle;

					} else {
						console.log("not==>");
						swal("Images not uploaded");
					}//file
				} else {
					console.log("format==>");
					swal("Allowed images formats are (jpg,png,jpeg)");
				}//file types
			}//file
			if (event.currentTarget.files) {
				main().then(formValues => {
					var profileImage = this.state.profileImage;
					//   for(var k = 0; k < formValues.length; k++){
					profileImage = formValues.profileImage
					//   }

					this.setState({
						profileImage: profileImage
					})
				});
				async function main() {
					var formValues = [];
					// for(var j = 0; j < profileImage.length; j++){
					var config = await getConfig();
					var s3url = await s3upload(profileImage.fileInfo, config, this);
					const formValue = {
						"profileImage": s3url,
						"status": "New"
					};
					formValues = formValue;
					// }
					return Promise.resolve(formValues);
				}
				function s3upload(image, configuration) {
					return new Promise(function (resolve, reject) {
						S3FileUpload
							.uploadFile(image, configuration)
							.then((Data) => {
								resolve(Data.location);
							})
							.catch((error) => {
							})
					})
				}
				function getConfig() {
					return new Promise(function (resolve, reject) {
						axios
							.get('/api/projectsettings/get/S3')
							.then((response) => {
								const config = {
									bucketName: response.data.bucket,
									dirName: 'propertiesImages',
									region: response.data.region,
									accessKeyId: response.data.key,
									secretAccessKey: response.data.secret,
								}
								resolve(config);
							})
							.catch(function (error) {})
					})
				}
			}
		}
	}
	render() {
		return (
			<div className="container-fluid">
				<div className="row px-2">
					<section className="pageContent col-12 pb-5">
						<div className="col-12 pageHeader pt-3">
							My Profile
						</div>

						<hr className="hr-head container-fluid row" />

						<form id="editUser" className="col-8 px-0 mx-auto">
							<div className="row">												
								<div className="col-12 px-0">
									<div className="row">
										<div className="col-12 mt-3 col-md-6">
											<label className="col-12 px-0">First Name <label className="requiredsign">*</label></label>
											<div className="col-12 px-0" id="firstNameErr">
												<input type="text" style={{ textTransform: 'capitalize' }}
													className="form-control"
													id="firstName" ref="firstName" name="firstName" value={this.state.firstName} onChange={this.handleChange} placeholder="First Name" />
											</div>
										</div>
										<div className="col-12 mt-3 col-md-6">
											<label className="col-12 px-0">Last Name <label className="requiredsign">*</label></label>
											<div className="col-12 px-0" id="lastNameErr">
												<input type="text" className="form-control"
													id="lastName" ref="lastName" name="lastName" value={this.state.lastName} onChange={this.handleChange} placeholder="Last Name" />
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-12 mt-3 col-md-6">
											<label className="col-12 px-0">Username/Email <label className="requiredsign">*</label></label>
											<div className="col-12 px-0" id="usernameErr">
												<input type="text" disabled value={this.state.username} onChange={this.handleChange} className="form-control" ref="username" name="username" required />
											</div>
										</div>
										<div className="col-12 mt-3 col-md-6 valid_box">
											<label >Mobile Number <span className="requiredsign">*</span></label>
											<input type="tel" minlength="10" maxlength="11" required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="formFloatingLabels form-control  newinputbox" ref="mobNumber" name="mobNumber" id="mobNumber" data-text="mobNumber" onChange={this.handleChange} value={this.state.mobNumber}
												placeholder="Mobile Number" />
										</div>
									</div>
								</div>

								<div className="col-12 mt-5">
									<button onClick={this.handleSubmit.bind(this)} className="col-12 col-md-4 my-5 btn resetBtn pull-right">Update</button>
								</div>
							</div>
						</form>
					</section>
				</div>
			</div>
		);
	}
}

export default EditUserProfile;


