import React, { useEffect, useState } from "react";
import Axios from "axios";
import Swal from 'sweetalert2';


export default () => {
   const NOTIFICATION_EVENTS = JSON.parse(process.env.REACT_APP_NotificationEvents);
   const [notifications, setNotifications] = useState({});
   const [saveDisabled, setSaveDisabled] = useState(true)
   const [user_id, setUser_id] = useState(false);
   const [roles, setRoles] = useState([]);

   useEffect(() => {
      const userDetails = localStorage.getItem("userDetails");
      const userDetailsParse = JSON.parse(userDetails);
      console.log("userDetailsParse =>", userDetailsParse);
      setUser_id(userDetailsParse.user_id);
      setRoles(userDetailsParse.roles);
   }, []);

   useEffect(() => {
      if (user_id) {
         Axios.get("/api/preferences/get/" + user_id)
            .then((response) => {
               console.log("response => ", response);
               delete response?.data?.preferences?._id
               setNotifications(response.data.preferences ?? {});
            })
            .catch(error => {
               console.log("error => ", error);
               Swal.fire("Something went wrong!", error.message, "error")
            })
      }
   }, [user_id]);

   const columns = ["Notification Events", "SMS", "EMAIL"]

   const handleChange = (event) => {
      saveDisabled && setSaveDisabled(false)
      const [type, notification] = event.target.name.split("-")
      const prevState = notifications[type] ?? {}
      const isChecked = event.target.checked
      if (notification === "SelectAll") {
         isChecked ? setNotifications({ ...notifications, [type]: [...NOTIFICATION_EVENTS] }) : setNotifications({ ...notifications, [type]: [] });
         return
      }
      isChecked && !prevState.includes(notification) ? prevState.push(notification) : prevState.splice(prevState.indexOf(notification), 1)
      setNotifications({ ...notifications, [type]: [...prevState] });
      console.log(notifications);
   }

   const handleSave = () => {
      console.log("notifications => ", notifications);
      var formValues = {
         user_id: user_id,
         email: notifications.email,
         sms: notifications.sms,
      }
      Axios.post("/api/preferences/set", formValues)
         .then((response) => {
            if (response.data.success) {
               setNotifications({
                  email: response.data.preferences.email,
                  sms: response.data.preferences.sms,
               });
            }
            Swal.fire("Notification preferences have been updated successfully","","success");
         })
         .catch(error => {
            console.log("error => ", error);
            Swal.fire("Something went wrong!", error.message, "error")
         })
   }


   return (
      <div className="container-fluid mx-0 px-0">
         <section className="content">
            <div className="col-12 pageContent">
               <div className="col-12 pt-4 pageHeader">
                  Notification Events
               </div>
               <hr className="hr-head mt-0 mb-5 container-fluid row" />
               <div className="container pt-3 col-12 overflow-auto scroll-auto max-height-60vh">
                  <div className="row">
                     <table className="table table-hover table-bordered table-striped table-height tableFixHead col-lg-8 col-xs-12 mx-auto">
                        <thead className="tempTableHeader">
                           <tr>
                              {columns.map((column) => (
                                 <th scope="col"
                                    className="w100 text-uppercase py-2">
                                    <h5>{column}</h5>
                                 </th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td className="col-md-4 col-sm-4, col-lg-4 pl-5 text-left align-middle" >
                                 <h5>Select All
                                 </h5>
                              </td>
                              <td className="col-md-4 col-sm-4, col-lg-4 text-center align-middle" >
                                 <input type="checkbox"
                                    className="wh30"
                                    checked={NOTIFICATION_EVENTS.filter(event => notifications?.sms?.includes(event)).length === NOTIFICATION_EVENTS?.length}
                                    name={`sms-SelectAll`}
                                    onChange={handleChange}
                                 />

                              </td>
                              <td className="col-md-4 col-sm-4, col-lg-4 text-center align-middle" >
                                 <input type="checkbox"
                                    className="wh30"
                                    checked={NOTIFICATION_EVENTS.filter(event => notifications?.email?.includes(event)).length === NOTIFICATION_EVENTS?.length}
                                    name={`email-SelectAll`}
                                    onChange={handleChange}
                                 />

                              </td>

                           </tr>

                           {
                              NOTIFICATION_EVENTS
                                 .filter(event => roles.includes("MSS - Medical Sales Specialist") && event.includes("Order") ||
                                    !roles.includes("MSS - Medical Sales Specialist"))
                                 .map((rowData) => {
                                    return (
                                       <tr>
                                          <td className="col-md-4 col-sm-4 col-lg-4 p-1 pl-5 text-left align-middle" >
                                             <h5>{rowData}</h5>
                                          </td>
                                          <td className="col-md-4 col-sm-4 col-lg-4 p-1 text-center align-middle" >
                                             <input type="checkbox"
                                                className="wh30"
                                                checked={notifications?.sms?.includes(rowData)}
                                                name={`sms-${rowData}`}
                                                onChange={handleChange}
                                             />
                                          </td>
                                          <td className="col-md-4 col-sm-4 col-lg-4 p-1 text-center align-middle" >
                                             <input type="checkbox"
                                                className="wh30"
                                                checked={notifications?.email?.includes(rowData)}
                                                name={`email-${rowData}`}
                                                onChange={handleChange}
                                             />
                                          </td>
                                       </tr>
                                    )
                                 })
                           }
                        </tbody>
                     </table>
                     <div className="submitButtonWrapper pdleftclr col-6 mx-auto">
                        <button className="col-12 px-lg-5 px-0 btn resetBtn" onClick={handleSave}>
                           Save
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
};
