import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import TemplateRow from './emails/TemplateRow.jsx';
import EmailTemplateRow from './emails/EmailTemplateRow.jsx';
import NotificationTemplateRow from './notifications/NotificationTemplateRow.jsx';
import AllNotificationTemplateRow from './notifications/AllNotificationTemplateRow.jsx';
import AllSMSTemplateRow from './sms/AllSMSTemplateRow.jsx';
import SMSTemplateRow from './sms/SMSTemplateRow.jsx';
import CKEditor from "react-ckeditor-component";
// import validator from 'validator';
import './notification.css';
import validate               from 'jquery-validation';

var NotificationEvents = process.env.REACT_APP_NotificationEvents;


// import NotificationEvents 		from 	"../../../notificationEvents.json";


class ViewTemplates extends Component {


	constructor(props) {
		super(props);
		this.state = {
			templateType: props.templateType ? props.templateType : "Email",
			templateName: props.templateName ? props.templateName : "",
			subject: props.subject ? props.subject : "",
			editorEmail: null,
			editorNotification: null,
			editorSMS: null,
			contentError: '',
			defaultLabel: ' --Select-- ',
			subjecterror: '',
			templateNameerror: '',
			templateTypeerror: '',
			emailTemplates: {},
			notificationTemplates: {},
			smsTemplates: {},
			selector: {},
			event:"",
			filterEvent:"",
			formerrors: {
				message: '',
				subject: '',

			},
			role:"",
			filterRole:"",
			roleArray:[],
			status:"active",
			filterStatus:"",
			company:"All",
			filterCompany:"All",
			companyArray:[],
			companyname:"",
			filteredEmailTemplatesCount : 0,
			filteredNotifTemplatesCount : 0,
			filteredSMSTemplatesCount : 0,
			emailTemplatesListCount : 0,
			notificationTemplatesListCount : 0,
			smsTemplatesListCount : 0,
			emailTemplatesList:[],
			notificationTemplatesList: [],
			smsTemplatesList: [],
			tabtype : 'emailTemplates',
			NotificationEvents : JSON.parse(NotificationEvents)
		};
		this.updateContent = this.updateContent.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeNotification = this.onChangeNotification.bind(this);
		this.onChangeSMS = this.onChangeSMS.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getEmailData = this.getEmailData.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.getNotiData = this.getNotiData.bind(this);
		this.getSmsData = this.getSmsData.bind(this);
	}

	handleChange(event) {
		const datatype = event.target.getAttribute('data-text');
		const { name, value } = event.target;
		let formerrors = this.state.formerrors;
		this.setState({
			formerrors,
			[name]: value
		});
	}


	componentDidMount() {
		console.log("1 NotificationEvents => ",this.state.NotificationEvents[0]);
      this.getRoles();

		axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token");
		$("html,body").scrollTop(0);
		this.getData();
		$.validator.addMethod("regxtemplateName", function (value, element, arg) {
			return arg !== value;
		}, "Please select the template Name ");
		$.validator.addMethod("regxEvent", function (value, element, arg) {
            return  arg !== value;
        }, "Please select the Event.");
      $.validator.addMethod("regxRole", function (value, element, arg) {
            return arg !== value;
      }, "Please select the Role.")
      $.validator.addMethod("regxStatus", function (value, element, arg) {
            return arg !== value;
      }, "Please select the Status.");
		
		$("#newTemplateForm").validate({
		      rules: {
		        	event: {
                    required: true,
                    regxEvent: ""
               },
		         templateName: {
		          	required: true,
		          	regxtemplateName: ""
		        	}, 
		        
		        	subject: {
		          	required: true,
		        	}, 
		        	role: {
                    required: true,
                    regxRole: ""
               },
               status: {
                  required: true,
                  regxStatus: "--Select Status--"
               },
      		},
		      errorPlacement: function(error, element) {
		        	if(element.attr("name") === "event") {
		            error.insertAfter("#event");
		         }
		        	if(element.attr("name") === "templateName"){
		          	error.insertAfter("#templateName");
		        	}
		        	if(element.attr("name") === "subject"){
		         	error.insertAfter("#subject");
		        	}
		        	if(element.attr("name") === "editorEmail"){
		         	error.insertAfter("#editorEmail");
		        	}
		        	if (element.attr("name") === "editorNotification"){
		         	error.insertAfter("#editorNotification");
		        	}
		        	if (element.attr("name") === "editorSMS"){
		         	error.insertAfter("#editorSMS");
		        	}
		        	if (element.attr("name") === "role") {
		            error.insertAfter("#role");
		        	}
		        	if (element.attr("name") === "status") {
		         	error.insertAfter("#status");
		         }
		      }
   	});	
	}

	componentWillReceiveProps(nextProps) {
		this.getData();
		this.getFilteredTemplate(this.state.selector);
	}
	getRoles() {
        var data = {
	      "startRange": 0,
	      "limitRange": 100000,
	    }
	    axios.post('/api/roles/get/list', data)
	      .then((response) => {
	        this.setState({
	          roleArray: response.data
	        }, () => {
	        })
	      }).catch(function (error) {
	      });
   }

	AllNotificationTemplates() {
		const id = this.state.currentNotificationId;
		var notificationTemplates = this.state.notificationTemplates;
		if (notificationTemplates && notificationTemplates.length > 0) {
			for (var i = 0; i < notificationTemplates.length; i++) {
				if (notificationTemplates[i]._id === id) {
					$('.defaultNotification').css({ 'display': 'block' });
					return [notificationTemplates[i]];
				}
			}
		} else {
			return [];
		}
		return [];
	}

	getData() {
		axios({
			method: 'get',
			url: '/api/masternotifications/get/list',
		}).then((response) => {
			console.log("response => ",response);
			var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
			var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
			this.setState({
				emailTemplatesList: emailTemplatesList,
				emailTemplatesListCount: emailTemplatesList.length,
				smsTemplatesList: smsTemplatesList,
				smsTemplatesListCount: smsTemplatesList.length
			});

		}).catch(function (error) {

		});
	}

	AllsmsTemplates() {
		const id = this.state.currentSMSId;
		var smsTemplates = this.state.smsTemplates;
		if (smsTemplates && smsTemplates.length > 0) {
			for (var i = 0; i < smsTemplates.length; i++) {
				if (smsTemplates[i]._id === id) {
					$('.defaultSMS').css({ 'display': 'block' });
					return [smsTemplates[i]];
				}
			}
		} else {
			return [];
		}
		return [];
	}

	getId(id){
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				emailTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})
	}
	getEmailData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					emailTemplates: response.data
				})
			});
		}
	}

	getNotiData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					notificationTemplates: response.data
				})
			});
		}
	}

	getSmsData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					smsTemplates: response.data
				})
			});
		}
	}


	getNotificationId(id) {
		
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				notificationTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})

	}
	getSmsId(id) {
		
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				smsTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})
	}

	deleteData(type, id) {
		if (type && id) {
			if (type === "Email") {
				var emailarray = [...this.state.emailTemplatesList]; // make a separate copy of the array
				var index = emailarray.findIndex((obj) => { return obj._id === id });
				if (index !== -1) {
					emailarray.splice(index, 1);
					this.setState({ emailTemplatesList: emailarray, emailTemplates: {} }, () =>
						this.getData());
				}
			} else if (type === "Notification") {
				var notificationarray = [...this.state.notificationTemplatesList]; // make a separate copy of the array
				var notificationindex = notificationarray.findIndex((obj) => { return obj._id === id });
				if (notificationindex !== -1) {
					notificationarray.splice(notificationindex, 1);
					this.setState({ notificationTemplatesList: notificationarray, notificationTemplates: {} });
				}
			} else if (type === "SMS") {
				var smsarray = [...this.state.smsTemplatesList]; // make a separate copy of the array
				var smsindex = smsarray.findIndex((obj) => { return obj._id === id });
				if (smsindex !== -1) {
					smsarray.splice(smsindex, 1);
					this.setState({ smsTemplatesList: smsarray, smsTemplates: {} });
				}
			}
		}
	}

	selectType(type,event) {
		const target = event.target;
		const name = target.name;
		this.setState({
			templateType: type,
		})
	}

	updateContent(newContent) {
		this.setState({
			editorEmail: newContent,
			editorNotification: newContent,
			editorSMS: newContent
		})
	}

	onChangeEmail(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorEmail: newContent
		}, () => {
			if (this.state.editorEmail) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	onChangeNotification(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorNotification: newContent
		}, () => {
			if (this.state.editorNotification) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	onChangeSMS(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorSMS: newContent
		}, () => {
			if (this.state.editorSMS) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	submitTemplate(event) {
		event.preventDefault();
		var event = this.state.event;
		var role = this.state.role;
		var company = this.state.company;
		var status = this.state.status;
		var templateType = this.state.templateType;
		var subject = this.state.subject;
		var emailContent = this.state.editorEmail;
		var notificationContent = this.state.editorNotification;
		var smsContent = this.state.editorSMS;
		if(company === 'All'){
			company = null
		}
		if(event && role){
			if(templateType === 'Email'){
				if(subject === "" & emailContent === null || emailContent === ""){
					swal("For Email Template Subject & Message are mandatory")
				}else{					
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"subject": subject,
						"content": emailContent,
					}
					axios.post('/api/masternotifications/post', formValues)
				  	.then(function (response) {
				    // handle success
				    	if(response.data.message === "Notification Details already exists"){
				    		swal("Email Template already exists")				    		
				    	}else{				    		
					    	swal({title: "Email Template added successfully"});
						}
				    	 // window.location.reload();
				  	})
				  	.catch(function (error) {
				    	// handle error
				    	console.log(error);
				  	});
					// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						

						this.setState({
									subject: "",
									editorEmail: null
								});
					}).catch(function (error) {
					});
				}
			}else if(templateType === 'Notification'){
				if(notificationContent === null || notificationContent === ""){
					swal("Please enter message")
				}else{
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"content": notificationContent,
					}
					axios.post('/api/masternotifications/post', formValues)
				  	.then(function (response) {
				    // handle success
				    	if(response.data.message === "Notification Details already exists"){
				    		swal("Notification Template already exists")
				    		
				    	}else{
				    		
					    	swal({
								title: "Notification template added successfully",		
							});
						}
				    	 // window.location.reload();
				  	})
				  	.catch(function (error) {
				    // handle error
				    	console.log(error);
				  	});
					// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						
						this.setState({
									editorNotification: null
								});
						
					}).catch(function (error) {});
				}
			}else{
				if(smsContent === null || smsContent === ""){
					swal("Please enter message")
				}else{
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"content": smsContent,
					}
					axios.post('/api/masternotifications/post', formValues)
					  	.then(function (response) {
					    // handle success
					    	if(response.data.message === "Notification Details already exists"){
					    		swal("SMS Template already exists")				    		
					    	}else{				    		
						    	swal({title: "SMS template added successfully",});
							}
					    	 // window.location.reload();
					  	})
					  	.catch(function (error) {
					    		// handle error
					    		console.log(error);
					  	});
						// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						
						this.setState({
									editorSMS: null
								});
						
					}).catch(function (error) {});
				}
			}
		}else{
			swal("Please select Event & Role")
		}		
	}

	closeModal(event){
		event.preventDefault();
		$('#createNotifyModal').hide();
    	$(".modal-backdrop").remove();
    	 window.location.reload();
	}

	getInfo(event){
		event.preventDefault();
		window.open('/NotificationVariableList','_blank');
	}

	showToken(event){
		$('.showTokens').toggle();
	}

	selectFilter(event){
		$(".filterWrapper").toggle();
	}

	resetFilter(event) {
		event.preventDefault();
		this.setState({
			filterStatus:'',
			filterEvent:'',
			filterRole:'',
			filterCompany:'All',
			filteredEmailTemplatesCount:0,
			filteredNotifTemplatesCount:0,
			filteredSMSTemplatesCount:0,
			emailTemplates: {},
			notificationTemplates: {},
			smsTemplates: {},
			selector:{}
		},()=>{
			axios({
				method: 'get',
				url: '/api/masternotifications/get/list',
			}).then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					emailTemplatesListCount: emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					notificationTemplatesListCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					smsTemplatesListCount: smsTemplatesList.length
				});
				
				this.setState({
							editorSMS: null
						});
				
			}).catch(function (error) {});
		})
	}

	onSelectedItemsChange(filterType, selecteditems){
		var selector=this.state.selector;
		this.setState({
	      [selecteditems.currentTarget.getAttribute('name')]: selecteditems.currentTarget.value
	    });
		
		if(filterType === 'filterEvent'){
			selector.filterEvent  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterRole'){
			selector.filterRole  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterStatus'){
			selector.filterStatus  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterCompany'){
			selector.filterCompany  = selecteditems.currentTarget.value; 
		}
		this.setState({	selector: selector },()=>{
			this.getFilteredTemplate(this.state.selector);
		})
	}

	getFilteredTemplate(selector){
		axios.post("/api/masternotifications/get/filterTemplate", selector)
			.then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					filteredEmailTemplatesCount:emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					filteredNotifTemplatesCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					filteredSMSTemplatesCount:smsTemplatesList.length
				});
				
				this.setState({
					editorSMS: null,
					emailTemplates: {},
					notificationTemplates: {},
					smsTemplates: {},
				});
				$('.defaultMsg').css({'display':'block'});
				$('.defaultNotification').css({'display':'block'});
				$('.defaultSMS').css({'display':'block'});
			})
			.catch((error) => {
			})
	}

	changeTab = (data)=>{
      this.setState({
        tabtype : data,
      })
   }

   showModal(event){
    	event.preventDefault();
    	$('#createNotifyModal').show()
   }


	render() {
		const {formerrors} = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<section className="content col-12">
						<div className="col-12 pageContent ">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 titleaddcontact">
									<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 contactdeilsmg pageHeader">
									Template Management
									</div>
									<hr className="hr-head container-fluid row" />
								</div>

								<div className="row">
									<div className="col-12 mb-5">
										<div className="col-lg-3 col-md-3  col-sm-6 col-xs-12" id="createmodalcl">
											<a href="/CreateTemplate"> <button className="col-12 btn btn-primary" ><i className="fa fa-plus" aria-hidden="true"></i><b> &nbsp;&nbsp;&nbsp;Add Template</b></button> </a>
										</div>												
									</div>

									<div className="col-12">
										<div className="col-12 px-5">
											<ul className="nav nav-pills col-12 col-md-6 row margin-top-30">
												<li className="nav-item col-5 bg-default">
													<a className="nav-link text-center px-0 px-lg-5 active col-12" data-toggle="pill" href="#emailTemplates" > <big><b>Email</b></big> </a>
												</li>
												<li className="nav-item col-5 text-center bg-default">
													<a className="nav-link px-lg-5 px-0 col-12" data-toggle="pill" href="#smsTemplates">  <big><b>SMS</b></big> </a>
												</li>
											</ul>

											<div className="col-12 py-3 mt-5">
												<h4 className=""><i className="fa fa-envelope" aria-hidden="true"></i> Template Library </h4>
											</div>

											<div className="tab-content p-0">
												<div id="emailTemplates" className="tab-pane fade in active px-0 table-wrapper-scroll-y">
													<div className="col-12 px-0">														
														<div className="sidertemplatebar col-lg-3 col-md-3 col-xs-12 col-sm-12 my-custom-scrollbar">
															<div className="row">
																<TemplateRow getId={this.getId.bind(this)} emailTemplatesList={this.state.emailTemplatesList} />
															</div>
														</div>

														<div className="saveTemplateWrapper col-lg-9 col-md-9 col-xs-12 col-sm-12 col-xs-12 mt-10">
															<div className="defaultMsg">
																<h1>Please Select The Template</h1>
																<i className="fa fa-hand-o-left" aria-hidden="true"></i>
															</div>
															{
																this.state.emailTemplates 
																? 
																	<EmailTemplateRow 
																			deleteData={this.deleteData.bind(this)} 
																			getData={this.getData.bind(this)} 
																			getEmailData={this.getEmailData.bind(this)} 
																			emailtemplateValues={this.state.emailTemplates} 
																			company={this.state.companyname} 
																	/> 
																: 
																	null
															}
														</div>
													</div>
												</div>

												<div id="smsTemplates" className="tab-pane px-0">
													<div className="col-12 px-0">
														<div className="sidertemplatebar col-lg-3 col-md-3 col-xs-12 col-sm-12">
															<div className="row">
																<SMSTemplateRow 
																		getSmsId={this.getSmsId.bind(this)} 
																		smsTemplatesList={this.state.smsTemplatesList} 
																/>
															</div>
														</div>
														<div className="saveTemplateWrapper col-lg-9 col-md-9 col-xs-12 col-sm-12">
															<div className="defaultSMS">
																<h1>Please Select The Template</h1>
																<i className="fa fa-hand-o-left" aria-hidden="true"></i>
															</div>
															{
																this.state.smsTemplates 
																? 
																	<AllSMSTemplateRow 
																			deleteData={this.deleteData.bind(this)} 
																			getSmsData={this.getSmsData.bind(this)} 
																			company={this.state.companyname} 
																			smstemplateValues={this.state.smsTemplates} 
																	/> 
																: 
																	null
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>

		);
	}
}
export default ViewTemplates;