import React, { Component } from 'react';
import swal from 'sweetalert';
import $ from "jquery";
import jQuery from 'jquery';
import axios from 'axios';
import './SignUp.css';

class ConfirmOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false
    }
  }
  componentDidMount() {
    $.validator.addMethod("regxemailotp", function (value, element, regexpr) {
      return regexpr.test(value);
    }, "Please enter a valid Verification code.");

    jQuery.validator.setDefaults({
      debug: true,
      success: "valid"
    });

    $("#OTPMobMail").validate({
      rules: {
        emailotp: {
          required: true,
          regxemailotp: /^[1-9][0-9]{3}$/,
        },
      },
      errorPlacement: function (error, element) {
        if (element.attr("name") === "emailotp") {
          error.insertAfter("#emailotp");
        }
      }
    });
  }
  confirmOTP(event) {
    event.preventDefault();
    var url = this.props.match.params;
    var formValues = {
      "user_ID": this.props.match.params.userID,
      "emailOTP": parseInt(this.refs.emailotp.value),
      "status": "Active"
    }
    if ($("#OTPMobMail").valid()) {
      axios.get('/api/auth/get/checkemailotp/usingID/' + this.props.match.params.userID + '/' + this.refs.emailotp.value)
        .then((response) => {

          if (response.data.message === 'SUCCESS') {
            swal('Verification code Verified Successfully.');
            var url = localStorage.getItem('previousUrl');
            if (url === 'forgotpassword') {
              localStorage.removeItem("previousUrl");
              this.props.history.push('/reset-pwd/' + this.props.match.params.userID);
            } else {
              localStorage.removeItem("previousUrl");
              this.props.history.push('/login');
            }
          } else {
            swal('Please enter valid Verification code.');
          }
        })
        .catch((error) => {
          swal(error.response.data.message);
        })
    }

  }
  inputEffect(event) {
    event.preventDefault();
    if ($(event.target).val() !== "") {
      $(event.target).addClass("has-content");
    } else {
      $(event.target).removeClass("has-content");
    }
  }
  resendOtp(event) {
    const userid = this.props.match.params.userID;
    if ($("#OTPMobMail").valid()) {
      document.getElementById("logInBtn").innerHTML = 'Please wait...';
      var formValues = {
        "emailSubject": "Email Verification",
        "emailContent": "As part of our registration process, we screen every new profile to ensure its credibility by validating email provided by user. While screening the profile, we verify that details put in by user are correct and genuine.",
      }
      axios.patch('/api/auth/patch/setsendemailotpusingID/' + userid, formValues)
        .then((response) => {
          document.getElementById("logInBtn").innerHTML = 'Resend Verification Code';
          swal("Verification code re-sent to your registered Email ID.");
        })
        .catch((error) => {
          swal(" Failed to resent Verification code");
          document.getElementById("logInBtn").innerHTML = 'Resend Verification Code';
        })
    }
  }
  Closepagealert(event) {
    event.preventDefault();
    $(".toast-error").html('');
    $(".toast-success").html('');
    $(".toast-info").html('');
    $(".toast-warning").html('');
    $(".toast-error").removeClass('toast');
    $(".toast-success").removeClass('toast');
    $(".toast-info").removeClass('toast');
    $(".toast-warning").removeClass('toast');

  }
  render() {

    var resendOtpWrap = "resendOtpWrap resendOtpWrapcss";
    var mobileEmail = 'Mobile Number';
    var resendOtp = "";

    return (
      <div style={{ 'height': window.innerHeight + 'px', 'width': window.innerWidth + 'px' }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 LoginWrapper">
        <div className="col-12 mt-5">
          <div className="col-12 col-lg-2 rounded px-4 py-3 logoWrapper">
            <img src="/images/Logo.png" className="logo" alt="logo" />
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5">
            <img src="/images/periscopeLogo.png" className="periscopeLogo" alt="Periscope" />
          </div>
          <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5 text-center">
            <div className="col-12 col-lg-10 offset-lg-1 loginCase">
              <div className="col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 innloginwrap mb25">
                  <h3>Confirm Verification Code</h3>
                </div>
                {
                  this.state.showMessage === false ?
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <p>We've sent you an Verification Code to your registered Email ID.</p>
                      <div className="">
                        <span> Please enter your Verification Code below.<br /></span>
                      </div>
                      <form id="OTPMobMail" className="textAlignLeft">
                        <div className="">

                          <br />
                          <div className="input-group " id="emailotp">
                            <input type="number" className="form-control" ref="emailotp" name="emailotp" placeholder="Enter Verification Code" onBlur={this.inputEffect.bind(this)} aria-describedby="basic-addon1" maxLength="4" pattern="(0|[0-9]*)" required />
                            <span className="input-group-addon glyphi-custommm"><i className="fa fa-key" aria-hidden="true"></i></span>
                          </div>
                        </div>
                        <div className="loginforgotpass mt25">
                          <lable>Found your Password?</lable>&nbsp;<a href='/login' className="">Sign In <b>&#8702;</b></a>
                        </div>
                        <div className="mt30 col-lg-12 row mb25">
                          <div className="col-lg-6 col-6 pl-0">
                            <div id="logInBtn"  onClick={this.resendOtp.bind(this)} className="bg-primary col-lg-12 btn  systemsecBtn px-1">
                              Resend
                            </div>
                          </div>
                          <div className="col-lg-6 col-6 pr-0">
                            <button id="logInBtn" type="submit" onClick={this.confirmOTP.bind(this)} className="col-lg-12 btn loginBtn systemsecBtn px-1">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    :
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <p className="textAlignCenter">Your Account is verified successfully! Please Sign In to access your account.<br /></p>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt15">
                        <a href="/login" className="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12 button3  btn btn-warning  signupbtn">Sign In</a>
                      </div>
                    </div>
                }

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default ConfirmOtp;