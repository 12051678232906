import React from 'react';
import Layout           from './Layout.js';
import axios            from 'axios';
import SessionsManager  from './strive/common/SessionManager'

import 'bootstrap/dist/css/bootstrap.min.css';
import './strive/css/root.css';
import './App.css';
import './responsive.css';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';


function App() {
  return (
    <div>
      <SessionsManager/>
      <Layout />
    </div>
  ); 
}

export default App;
