import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import jQuery from 'jquery';
import "./userManagement.css";
// import PlacesAutocomplete, {
// 	geocodeByAddress,
// 	getLatLng
// } from "react-places-autocomplete";
// import PersonMaster from '../../Master/PersonMaster/PersonMaster.js';
var entity = ""
class EditUserProfile extends Component {
	constructor(props) {
		super(props);
		var UserId = this.props.match.params.id;

		this.state = {
			UserId: UserId,
			fullname: "",
			username: "",
			mobNumber: "",
			userProfile: "",
			firstName: "",
			lastName: "",
			entity : entity,
			centerName: "",
			user: "user",
			// gmapsLoaded: false,
		}
		this.handleChange = this.handleChange.bind(this);
	}

	// initMap = () => {
 //      this.setState({
 //        gmapsLoaded: true,
 //      })
 //    }
 
	componentDidMount() {
		

		this.getDepartment();
		this.userdata();
		this.getDesignation();
		var role = [];
			var getCompany_Id = localStorage.getItem("company_Id")
			var getcompanyID = localStorage.getItem("companyID")
			var companyName = localStorage.getItem("companyName")
			role.push(localStorage.getItem("roles"));
			this.setState({
				listOfRoles: role,
			})
			if (role.indexOf("admin") == -1) {
				this.setState({
					companyID: getcompanyID,
					corporate_Id: getCompany_Id,
					corporate: companyName
				}, () => {
					this.getEntityLocation(getCompany_Id);
				})
			}

			
		const firstnameRegex = RegExp(/^[A-za-z']+( [A-Za-z']+)*$/);
		const lastnameRegex = RegExp(/^[A-za-z']+( [A-Za-z']+)*$/);
		const mobileRegex = RegExp(/^[0-9][0-9]{9}$|^$/);
		const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/);
		$.validator.addMethod("regxCenter", function (value, element, regexpr) {
			return value !== regexpr;
		}, "This field is required.");
		$.validator.addMethod("regxEmail", function (value, element, regexpr) {
			return regexpr.test(value);
		}, "Please enter a valid email address.");
		$.validator.addMethod("regxMobile", function (value, element, regexpr) {
			return regexpr.test(value);
		}, "Please enter a valid mobile number.");
		$.validator.addMethod("regxName", function (value, element, regexpr) {
			return regexpr.test(value);
		}, "It should only contain alphabets.");
		$("#editUser").validate({
			rules: {
				firstName: {
					required: true,
					regxName: firstnameRegex
				},
				lastName: {
					required: true,
					regxName: lastnameRegex
				},
				username: {
					required: true,
					regxEmail: emailRegex
				}
			},
			errorPlacement: function (error, element) {
				if (element.attr("name") === "firstName") {
					error.insertAfter("#firstNameErr");
				}
				if (element.attr("name") === "lastName") {
					error.insertAfter("#lastNameErr");
				}
				if (element.attr("name") === "username") {
					error.insertAfter("#usernameErr");
				}
			}
		});

	}
	componentWillReceiveProps(nextProps){
		this.userdata();
	}
	userdata(){
		var userid = this.state.UserId;
		axios.get('/api/users/get/' + userid)
			.then((res) => {
				this.setState({
					firstName: res.data.firstname,
					lastName: res.data.lastname,
					username: res.data.email,
					mobNumber: res.data.mobile,
					department: res.data.department,
					designation: res.data.designation,
					cityName: res.data.city,
					role: res.data.role,
					states: res.data.states,
					companyID: res.data.companyID,
					companyName: res.data.companyName,
				},()=>{
					if(this.state.role.indexOf("driver") >= 0){
							var entity = "driver";
							var myJSON = JSON.stringify(entity);
							this.setState({
								entity: myJSON,
								userdetails : res.data
							},()=>{
								console.log("this.state.entity driver===>",this.state.entity);
							})
					}else{
						var entity = "employee";
						var myJSON = JSON.stringify(entity);
						this.setState({
							entity: myJSON,
							userdetails : res.data
						},()=>{
							console.log("this.state.entity emp===>",this.state.entity);
						})
					}
				})
			})
			.catch((error) => {});
		
	}
	handleSubmit(event) {
		if ($('#editUser').valid()) {
			var userid = this.state.UserId;
			var formvalues = {
				"firstname": this.refs.firstName.value,
				"lastname": this.refs.lastName.value,
				"mobNumber": (this.state.mobNumber).replace("-", ""),
				"department": this.state.department,
				"designation": this.state.designation,
				"cityName": this.state.cityName,
				"states": this.state.states,
				"companyID": this.state.companyID,
				"companyName": this.state.companyName,
			}
			axios.patch('/api/users/patch/' + userid, formvalues)
				.then((response) => {
						this.updatePerson();
				})
				.catch((error) => {
					// window.location = '/dashboard';
				});
		}
	}
	backtoum() {
		this.props.history.push("/umlistofusers")
	}
	getDepartment() {
		axios.get("/api/departmentmaster/get/list")
			.then((response) => {
				// console.log("departmentArray==>",response.data)
				this.setState({
					departmentArray: response.data
				})
			})
			.catch((error) => {
			})
	}
	getDesignation() {
		axios.get("/api/designationmaster/get/list")
			.then((response) => {
				// console.log("designationArray==>",response.data)
				this.setState({
					designationArray: response.data
				})
			})
			.catch((error) => {
			})
	}
	handleChange(event) {
		const target = event.target.value;
		const name = event.target.name;
		this.setState({
			[name]: target,
		}, () => {
		})
	}

	render() {
		const searchOptions = {
			types: ['(cities)'],
			componentRestrictions: { country: "in" }
		}
		console.log("this.state.entity===>",this.state.entity);
		console.log("this.state.entity===>",this.state.userdetails);
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="formWrapper">
						<section className="content">
							<div className="col-12 pageContent ">
								<div className="col-12 contactdeilsmg pageHeader">
									Edit User Data
								</div>
								<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 " id="rolemodalcl">
									<button type="button" className="btn col-lg-12 col-md-12 col-sm-12 col-xs-12 addexamform userbtn clickforhideshow"
										onClick={this.backtoum.bind(this)}>
										<i className="fa fa-undo" aria-hidden="true"></i>
										<b>&nbsp;&nbsp; Back To UM List</b>
									</button>
								</div>
								<hr className="hr-head container-fluid row" />
								<div className="box-body">
									<div className="row">
										<form id="editUser">
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												{/* <PersonMaster userdetails={this.state.userdetails} userdets={this.state.user} entity="employee" /> */}
											</div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export default EditUserProfile;

