import React,{Component}  from 'react';
import { render }         from 'react-dom';
import $                  from "jquery";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Footer.css';

export default class Header2 extends Component{
  componentDidMount(){
    // if ( !$('body').hasClass('adminLte')) {
    //   var adminLte = document.createElement("script");
    //   adminLte.type="text/javascript";
    //   adminLte.src = "/js/adminLte.js";
    //   $("body").append(adminLte);
    // }
  }
    
  componentWillUnmount(){
    $("script[src='/js/adminLte.js']").remove();
    $("link[href='/css/dashboard.css']").remove();
  }
  render(){
    return(
      <footer id="main-footer" className="main-footer"> 
         <div className="col-12">
            <div className="row">
               <div className="col-12 col-md-5 text-center text-md-left">
                  <small> <strong className="footerTwoMargin">Copyright &copy; {new Date().getFullYear()} <a href="strivemedical.com">Strive Medical</a>.</strong>  
                  &nbsp; All rights reserved. </small>
               </div>

               <div className="col-12 col-md-7 text-center text-md-right">
                  <small> <strong className="WebsiteName"> Designed & Developed By <a href="http://intelera.tech/" className="comnm" target="_blank">IntelEra</a></strong></small>
               </div>

               <div className="hidden">
                 <strong className="WebsiteName"> Designed & Developed By <a href="http://iassureit.com/" className="comnm" target="_blank">iAssure International Technologies Pvt.Ltd.</a></strong>                
               </div>
            </div>
         </div>
      </footer>
    );
  }
}