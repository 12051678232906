import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";

export default (props) => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setLoader(true)
    Axios.get(`/api/orders/get/${props.match.params.id}`).then((response) => {
      setTimeout(() => {
        setLoader(false)
      }, 1000);
      response?.data?.order &&
        setOrderDetails({
          ...response.data.order,
          descriptions: [new Set(response.data.order.descriptions)]
        });
    });
    
  }, []);

  const columns = [
    {
      element1: "Order Number",
      key1: "orderNum",
      type1: "string",
      element2: "Order Received Date",
      type2: "date",
      key2: "orderReceivedDate",
      element4: "Voided Reason",
      key4: "voidedReason",
      type4: "string",
      element5: "Pending Reason",
      key5: "pendingReason",
      type5: "string",
    },
    {
      element1: "Patient Name",
      key1: "patientName",
      type1: "string",
      element2: "Patient DOB",
      type2: "string",
      key2: "patientDOB",
      element3: "Primary Payer",
      key3: "primaryPayer",
      type3: "string",
    },
    {
      element1: "Ordering Doctor",
      key1: "orderingDoctor",
      type1: "string",
      element2: "Referring Facility State",
      key2: "facilityState",
      type2: "string",
      element3: "Item HCPCs Code",
      key3: "hcpcCodes",
      type3: "array",
    },
    {
      element1: "Full Item Name",
      key1: "descriptions",
      type1: "array",
      element2: "Order Originated From",
      key2: "orderOrigin",
      type2: "string",
      element3: "Assigned To",
      key3: "assignedTo",
      type3: "string",
    },
    {
      element1: "Follow Up Status",
      key1: "followUpStatus",
      type1: "string",
      // element2: "Delivery Date",
      // key2: "deliveredDate",
      // type2: "date",
      element2: "Shipping Date",
      key2: "shippingDate",
      type2: "date",
      
      element6: "Order Forwarded To",
      key6: "orderForwarded",
      type6: "string",
    },
    {
      element1: "Tracking Number",
      key1: "trackNumber",
      type1: "string",
      element2: "MSS",
      key2: "marketingRep",
      type2: "string",
      element3: "Patient Account Group",
      key3: "accountGroup",
      type3: "string",
      
      element7: "Order Voided Date",
      key7: "voidedDate",
      type7: "date",
    },{
      element1: "SO Notes",
      key1: "soNotes",
      type1: "long-string",
      element2: "Sale Order Classification",
      key2: "soClassification",
      type2: "string",

    }
  ];

  return (<>
  {loader? 
<center className="table-empty container align-middle">

 <div className="loadingImg">
    <img src="/images/loading.gif" />
</div> 
</center>:
    <div className="pageContent col-12 pt-1">
      <div
        className="row col-12 align-center titleaddcontact pt-2 "
        style={{ height: "100%" }}
      >
        <div className="col-lg-4 col-xs-12 contactdeilsmg pageHeader float-left d-flex align-items-center text-align-center">
          Order Details
        </div>
        <div className="col-lg-4 col-xs-6 p-0 contactdeilsmg pageHeader float-center  ">
          {getStatus(orderDetails.status)}
        </div>
        <div className="col-lg-4 col-xs-6 contactdeilsmg contactdeilsmgId pageHeader  float-left">
          Brightree Patient ID
          <h4 className="m-lg-0 align-middle">{orderDetails.btPatientId}</h4>
        </div>
        <hr className="hr-head container-fluid row" />
      </div>
      <div className="container pt-3 col-12 overflow-auto scroll-auto max-height-60vh">
        {columns.map((item) => {
          const item1 =
            item.type1 == "date" && orderDetails[item.key1]
              ? moment(orderDetails[item.key1]).utc().format("MM/DD/YYYY")
              : orderDetails[item.key1] ?? "Not Available";
          const item2 =
            item.type2 == "date" && orderDetails[item.key2]
              ? moment(orderDetails[item.key2]).utc().format("MM/DD/YYYY")
              : orderDetails[item.key2] ?? "Not Available";
          const item3 =
            item.type3 == "date" && orderDetails[item.key3]
              ? moment(orderDetails[item.key3]).utc().format("MM/DD/YYYY")
              : item.type3 == "array" && orderDetails[item.key3]
                ? orderDetails[item.key3].filter(o => o != null).join(",")
                : orderDetails[item.key3] ?? "Not Available";

          return (
            
            <div className="row mb-4">
              {item.element1 != undefined && (
                <div
                  className={`${item.type1 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element1}</b>
                  </h4>
                  <h5>{item1}</h5>
                </div>
              )}
              {item.element2 != undefined && (
                <div
                  className={`${item.type2 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element2}</b>
                  </h4>
                  <h5>{item2}</h5>
                </div>
              )}

              {item.element3 != undefined && (
                <div
                  className={`${item.type3 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element3}</b>
                  </h4>
                  <h5>{item3}</h5>
                </div>
              )}

              {item.element4 != undefined && (orderDetails.status == "CANCELLED" ||
                orderDetails.status == "FORWARDED") && (
                  <div
                    className={`${item.type4 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                      }`}
                  >
                    <h4>
                      <b>{item.element4}</b>
                    </h4>
                    <h5>{orderDetails[item?.key4]}</h5>
                  </div>
                )}

              {item.element5 != undefined && orderDetails.status == "PENDING" && (
                <div
                  className={`${item.type5 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element5}</b>
                  </h4>
                  <h5>{orderDetails[item?.key5] ?? "Not Available"}</h5>
                </div>
              )}
              {item.element6 != undefined && orderDetails.status == "FORWARDED" && (
                <div
                  className={`${item.type6 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element6}</b>
                  </h4>
                  <h5>{orderDetails[item?.key6] ?? "Not Available"}</h5>
                </div>
              )}

              {item.element7 != undefined && ["CANCELLED", "FORWARDED"].includes(orderDetails.status) && (
                <div
                  className={`${item.type7 == "long-string" ? "col-12" : "col-lg-4 col-xs-6"
                    }`}
                >
                  <h4>
                    <b>{item.element7}</b>
                  </h4>
                  <h5>{orderDetails[item.key7] !== undefined ? moment(orderDetails[item.key7]).utc().format("MM/DD/YYYY") : "Not Available"}</h5>
                </div>
              )}

            </div>
          );
        })}
      </div>
    </div>
  }  </> );
};

const getStatus = (status) => (
  <div
    className={`my-lg-2 py-lg-2 my-0  py-0 
    rounded col-10 
    text-light align-items-center text-center header-order-details d-flex align-items-center
                  ${status == "SHIPPED" && "bg-success"}
                  ${status == "RECEIVED" && "bg-primary"}
                  ${status == "IN PROGRESS" && "bg-info"}
                  ${status == "PENDING" && "bg-warning"}
                  ${status == "CANCELLED" && "bg-danger"}
                  ${status == "FORWARDED" && "bg-secondary"}
                  `}
  >
    Order Status: {status}
  </div>
);
