import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import CKEditor from "react-ckeditor-component";
import './notification.css';
import validate    from 'jquery-validation';
var NotificationEvents = JSON.parse(process.env.REACT_APP_NotificationEvents);

class EditTemplate extends Component {

	constructor(props) {

		super(props);

		this.state = {
			NotificationEvents   : NotificationEvents,
			notifTemplate_type 	: "",
			notifTemplate_id 		: "",
			event 					: "",
			role 						: "",
			status 					: "",
			subject 					: "",
			contentError 			: '',
			defaultLabel 			: ' --Select-- ',
			subjecterror 			: '',
			formerrors : {
				message	: '',
				subject 	: '',
			},
			status:"active",
		};
	}

	handleChange(event) {
		const datatype = event.target.getAttribute('data-text');
		const { name, value } = event.target;
		let formerrors = this.state.formerrors;
		this.setState({
			formerrors,
			[name]: value
		});
	}

	componentDidMount() {
		$("html,body").scrollTop(0);
      this.getRoles();

		// console.log("this.props.match.params.type => ",this.props.match.params.type);
		// console.log("this.props.match.params.template_id => ",this.props.match.params.template_id);

		this.setState({
				notifTemplate_type 	: this.props.match.params.type,
				notifTemplate_id 		: this.props.match.params.template_id,	
			},
			()=>{
      		// console.log("NotificationEvents => ",this.state.NotificationEvents);
      		// console.log("notifTemplate_type => ",this.state.notifTemplate_type);
      		// console.log("notifTemplate_id => ",this.state.notifTemplate_id);
				this.getData();
			}
		)


		$.validator.addMethod("regxtemplateName", function (value, element, arg) {
			return arg !== value;
		}, "Please select the template Name ");
		$.validator.addMethod("regxEvent", function (value, element, arg) {
         return  arg !== value;
      }, "Please select the Event.");
      $.validator.addMethod("regxRole", function (value, element, arg) {
         return arg !== value;
      }, "Please select the Role.")
      $.validator.addMethod("regxStatus", function (value, element, arg) {
         return arg !== value;
      }, "Please select the Status.");
		$("#newTemplateForm").validate({
		   rules: {
		      event: {
                    required: true,
                    regxEvent: ""
            },
		      templateName: {
		         required: true,
		         regxtemplateName: ""
		      },		       
		      subject: {
		         required: true,
		      }, 
		      role: {
               required: true,
               regxRole: ""
            },
            status: {
               required: true,
               regxStatus: "--Select Status--"
            },
      	},

      	errorPlacement: function(error, element) {
	        	if (element.attr("name") === "event") {
	            error.insertAfter("#event");
	         }
	        	if (element.attr("name") === "templateName"){
	         	error.insertAfter("#templateName");
	        	}
	        	if (element.attr("name") === "subject"){
	         	error.insertAfter("#subject");
	        	}
	        	if (element.attr("name") === "editorEmail"){
	         	error.insertAfter("#editorEmail");
	        	}
	        	if (element.attr("name") === "editorNotification"){
	         	error.insertAfter("#editorNotification");
	        	}
	        	if (element.attr("name") === "editorSMS"){
	         	error.insertAfter("#editorSMS");
	        	}
	        	if (element.attr("name") === "role") {
	         	error.insertAfter("#role");
	        	}
	        	if (element.attr("name") === "status") {
	         	error.insertAfter("#status");
	         }
	      }
    	});	
	}

	getRoles() {
      var data = {
	      "startRange": 0,
	      "limitRange": 100000,
	   }
	   axios.post('/api/roles/get/list', data)
	      .then((response) => {
	        	this.setState({
	         	roleArray: response.data
	        	})
	      })
	      .catch( error => {
	      	swal.fire("Something went wron while fetching Roles",error.message,"error")
	      });
   }
    

	getData() {
		axios({
			method: 'get',
			url: '/api/masternotifications/get/'+this.state.notifTemplate_id,
		})
		.then((response) => {
			// console.log("response => ", response.data);
			if(response.data){
				this.setState({
					event 			: response.data.event,
					role 				: response.data.role,
					status 			: response.data.status,
					subject 			: response.data.subject,
					content 			: response.data.content,
					templateType 	: response.data.templateType,
				})
			}else{
      		swal.fire("Something went wrong while fetching template data","","error");
			}
		})
      .catch(error => {
      	swal.fire("Something went wron while fetching template data",error.message,"error");
      });
	}




	selectType(type,event) {
		const target = event.target;
		const name = target.name;
		this.setState({
			templateType: type,
		})
	}

	updateContent(newContent) {
		this.setState({
			editorEmail: newContent,
			editorNotification: newContent,
			editorSMS: newContent
		})
	}

	onChangeEmail=(evt)=> {
		var newContent = evt.editor.getData();
		this.setState({
			content: newContent
		})
	}

	onChangeNotification(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorNotification: newContent
		}, () => {
			if (this.state.editorNotification) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	onChangeSMS(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorSMS: newContent
		}, () => {
			if (this.state.editorSMS) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	submitTemplate(event) {
		event.preventDefault();
		var notifTemplate_id  = this.state.notifTemplate_id;
		var event 		= this.state.event;
		var role 		= this.state.role;
		var status 		= this.state.status;
		var templateType = this.state.templateType;
		var subject 	= this.state.subject;
		var content 	= this.state.content;
		var company 	= "";
		
		if(subject === "" && content === null || content === ""){
			swal("For Email Template Subject & Message are mandatory","","error");
		}else{					
			var formValues = {
				"notifTemplate_id" : notifTemplate_id,
				"event"   		: event,
				"role" 	 		: role,
				"status"  		: status,
				"company" 		: company,
				"subject" 		: subject,
				"content" 		: content,
				"templateType" : templateType,
			}

			axios.post('/api/masternotifications/post/notification-template', formValues)
		  		.then(response => {
		    		// handle success
		    		console.log("update response.data => ",response.data);
			    	if(response.data.success){
			    		swal("Notification Template updated successfully","","success");
			    		this.props.history.push('/ViewTemplates')
	          		window.location.reload();
			    	}
		  		})
			  	.catch(error=>{
			    	// handle error
			    	console.log("Something went wrong while updating notification template ", error.message, "error");
				   swal("Notification template not updated for some reason",error.message,"error");
			  	});
		}
	}

	closeModal(event){
		event.preventDefault();
		$('#createNotifyModal').hide();
    	$(".modal-backdrop").remove();
    	 window.location.reload();
	}


	getInfo(event){
		event.preventDefault();
		window.open('/NotificationVariableList','_blank');
	}

	showToken(event){
		$('.showTokens').toggle();
	}

	selectFilter(event){
		$(".filterWrapper").toggle();
	}

	resetFilter(event) {
		event.preventDefault();
		this.setState({
			filterStatus:'',
			filterEvent:'',
			filterRole:'',
			filterCompany:'All',
			filteredEmailTemplatesCount:0,
			filteredNotifTemplatesCount:0,
			filteredSMSTemplatesCount:0,
			emailTemplates: {},
			notificationTemplates: {},
			smsTemplates: {},
			selector:{}
		},()=>{
			axios({
				method: 'get',
				url: '/api/masternotifications/get/list',
			}).then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					emailTemplatesListCount: emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					notificationTemplatesListCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					smsTemplatesListCount: smsTemplatesList.length
				});
				
				this.setState({
							editorSMS: null
						});
				
			}).catch(function (error) {});
		})
	}

	onSelectedItemsChange(filterType, selecteditems){
		var selector=this.state.selector;
		this.setState({
	      [selecteditems.currentTarget.getAttribute('name')]: selecteditems.currentTarget.value
	    });
		
		if(filterType === 'filterEvent'){
			selector.filterEvent  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterRole'){
			selector.filterRole  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterStatus'){
			selector.filterStatus  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterCompany'){
			selector.filterCompany  = selecteditems.currentTarget.value; 
		}
		this.setState({	selector: selector },()=>{
			this.getFilteredTemplate(this.state.selector);
		})
	}

	getFilteredTemplate(selector){
		axios.post("/api/masternotifications/get/filterTemplate", selector)
			.then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					filteredEmailTemplatesCount:emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					filteredNotifTemplatesCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					filteredSMSTemplatesCount:smsTemplatesList.length
				});
				
				this.setState({
					editorSMS: null,
					emailTemplates: {},
					notificationTemplates: {},
					smsTemplates: {},
				});
				$('.defaultMsg').css({'display':'block'});
				$('.defaultNotification').css({'display':'block'});
				$('.defaultSMS').css({'display':'block'});
			})
			.catch((error) => {
			})
	}

	changeTab = (data)=>{
      this.setState({
        tabtype : data,
      })
   }

   showModal(event){
    	event.preventDefault();
    	$('#createNotifyModal').show()
   }


	render() {
		const {formerrors} = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<section className="content">
						<div className="col-12 pageContent">
							<div className="row">

								<div className="col-12 py-0 mb-5">
									<div className="col-12 py-4 pageHeader mb-2">
										Edit Notification Template
									</div>
									<hr className="mt-0 hr-head container-fluid row" />
								</div>


								<section className="col-12">
									<div className="row">
										<div className="col-10 mx-auto col-lg-3 ml-5 mt-4">
											<label>Event <sup className="astrick">*</sup></label>
                               	<select id="event" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" value={this.state.event} ref="event" name="event" onChange={this.handleChange.bind(this)}>
                                   	<option disabled value="">--Select Event--</option>
                                   	{
                                   		this.state.NotificationEvents && this.state.NotificationEvents.length > 0
                                   		?
	                                   		this.state.NotificationEvents.map((event,index)=>{
	                                   			var selected = this.state.event === event ? "selected" : "" ;
	                                   			return(
	                                   				<option value={event} key={index} selected> {event} </option>
	                                   			)

	                                   		})
	                                   	:
	                                   		swal("Please Provide Notification Events in .env file as REACT_APP_NotificationEvents array!")
                                   	}
                               	</select>   
										</div>

										<div className="col-10 mx-auto col-lg-3 mt-4">
											<label>Role<sup className="astrick">*</sup></label>
                                              <select id="role" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" value={this.state.role} ref="role" name="role" onChange={this.handleChange.bind(this)}>
                                                  <option value="">--Select Role--</option>
                                                  {
                                                      this.state.roleArray && this.state.roleArray.length > 0 ?
                                                         this.state.roleArray.map((data, i)=>{
                                                          	var selected = data.role === this.state.role ? 'selected' : '' ;
                                                          	// console.log("selected => ",selected);
                                                            return(
                                                            	<option key={i} value={data.role} selected>{data.role} </option>
                                                            );
                                                         })
                                                      :
                                                      null
                                                  }
                                              </select>
										</div>

										<div className="col-10 mx-auto col-lg-3 mt-4">
											<label className="labelform col-lg-12 col-md-12 col-sm-12 col-xs-12">Status<sup className="astrick">*</sup></label>
                                 <select id="status" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" ref="status" name="status" onChange={this.handleChange.bind(this)}>
                                    <option disabled value="">--Select Status--</option>
                                    {this.state.status === 'active' ? <option  selected> active </option> : <option> active </option> }
                                    {this.state.status === 'inactive' ? <option  selected> inactive </option> : <option> inactive </option> }
                                 </select>
										</div>
									</div>

									<div className="col-12 ml-lg-4 my-5">
										<div className="tab-content px-0 ">
											<div id="email" className="tab-pane fade in active">
												{
													this.state.notifTemplate_type === 'email'
													?
														<div className="col-12">
															<label>Subject <span className="astrick">*</span></label>
															<input type="text" name="subject" data-text="subject"  id="subject" value={this.state.subject} onChange={this.handleChange.bind(this)} className="subject form-control col-12 inputValid" required />
														</div>
													:
														null
												}

												<div className="col-12 mt-5">
													<label>Message <span className="astrick">*</span></label>
													<div className="" id="editorEmail">
														<CKEditor activeclassName="p15"  name="editorEmail" data-text="message" className="editorEmail" content={this.state.content} events={{ "change": this.onChangeEmail }}/>
													</div>
												</div>
										  	</div>

										  	{/*<div id="sms" className="tab-pane fade in">
												<div className="form-group">
													<label>Message <span className="astrick">*</span></label>
													<div className="col-12" id="editorSMS">
														<CKEditor activeclassName="p15"  name="editorSMS" data-text="message" className="editorSMS" content={this.state.editorSMS} events={{ "change": this.onChangeSMS }}/>
													</div>
												</div>
										  	</div>*/}
										</div>
									</div>

									<form className="newTemplateForm mb-5" id="newTemplateForm">
										<div className="row">
											<button onClick={this.submitTemplate.bind(this)} className="col-10 offset-1 col-md-3 offset-md-8 btn btn-primary button3 outlinebox mb-5">	
												Update Template
											</button>
										</div>
									</form>
								</section>
							</div>
						</div>
					</section>
				</div>
			</div>

		);
	}
}
export default EditTemplate;