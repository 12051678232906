import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import $                      from 'jquery';
import axios                  from 'axios';
import jQuery                 from 'jquery';
import swal                   from 'sweetalert';
import 'jquery-validation';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SignUp.css';


class Login extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      loggedIn: false,
      auth: {
        email: '',
        pwd: '',
      },
      messageData: {
        "type": "",
      }
    }
  }

   componentDidMount() {

      $.validator.addMethod("regxemail", function (value, element, regexpr) {
         return regexpr.test(value);
      }, "Please enter a valid email address.");

      jQuery.validator.setDefaults({
         debug: true,
         success: "valid"
      });
      
      $("#login").validate({
         rules: {
         loginusername: {
            required: true,
            regxemail : /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
         },
         loginpassword: {
            required: true
         }
      },
      errorPlacement: function (error, element) {
         if (element.attr("name") === "loginusername") {
            error.insertAfter("#loginusername");
         }
         if (element.attr("name") === "loginpassword") {
            error.insertAfter("#loginpassword");
         }
      }
    });
  }

   userlogin(event) {
      event.preventDefault();
      var auth = {
        email: this.refs.loginusername.value,
        password: this.refs.loginpassword.value,
      //   role: "admin"
      }
      if ($("#login").valid()) {
        // document.getElementById("logInBtn").value = 'Please Wait...';
        document.getElementById("logInBtn").value =
        this.setState({ btnLoading: true });
        axios.post('/api/auth/post/login', auth)
         .then((response) => {
            console.log("response login",response.data);
            if (response.data.message === "Login Auth Successful") {
              this.setState({ btnLoading: false });
              var userDetails = {
                firstName: response.data.userDetails.firstName,
                lastName: response.data.userDetails.lastName,
                email: response.data.userDetails.email,
                phone: response.data.userDetails.phone,
                companyID : parseInt(response.data.userDetails.companyID),
                pincode: response.data.userDetails.pincode,
                user_id: response.data.userDetails.user_id,
                roles: response.data.userDetails.roles,
                token: response.data.userDetails.token,
                orderFilter:response.data.userDetails.orderFilter
              }
              document.getElementById("logInBtn").value = 'Sign In';

              localStorage.setItem('userDetails', JSON.stringify(userDetails));
  
              this.setState({
                loggedIn: true
              }, () => {
               const redirectUrl=this.props.location?.state?.redirectUrl
                this.props.history.push(redirectUrl?redirectUrl:'/order-list')
                window.location.reload();
              })
            } else if (response.data.message === "USER_BLOCK") {
              swal({
                text: "You are blocked by admin. Please contact Admin."
              });
              document.getElementById("logInBtn").value = 'Sign In';
            } else if (response.data.message === "NOT_REGISTER") {
              swal({
                text: "This Email ID is not registered. Please try again."
              });
              document.getElementById("logInBtn").value = 'Sign In';
            } else if (response.data.message === "INVALID_PASSWORD") {
              swal({
                text: "You have entered wrong password. Please try again."
              });
              document.getElementById("logInBtn").value = 'Sign In';
            } else if (response.data.message === "USER_UNVERIFIED") {
              swal({
                text: "You have not verified your account. Please verify your account."
              })
                .then((value) => {
                  var emailText = {
                    "emailSubject": "Email Verification",
                    "emailContent": "As part of our registration process, we screen every new profile to ensure its credibility by validating email provided by user. While screening the profile, we verify that details put in by user are correct and genuine.",
                  }
                  axios.patch('/api/auth/patch/setsendemailotpusingEmail/' + this.refs.loginusername.value, emailText)
                    .then((response) => {
                      swal("We send you a Verification Code to your registered email. Please verify your account.");
                      this.props.history.push("/confirm-otp/" + response.data.userID);
                    })
                    .catch((error) => {
                      swal(" Failed to sent OTP");
                    })
                });
              document.getElementById("logInBtn").value = 'Sign In';
  
            }
          })
          .catch((error) => {
            console.log("error", error);
            swal({
              text: "Please enter valid Email ID and Password"
            })
            this.setState({ btnLoading: false });
            // document.getElementById("logInBtn").value = 'Sign In';
            // if (localStorage !== null) {
            // }
          });
      }
   }

   showSignPass() {
      $('.showPwd').toggleClass('showPwd1');
      $('.hidePwd').toggleClass('hidePwd1');
      return $('#loginpassword').attr('type', 'text');
   }
   hideSignPass() {
      $('.showPwd').toggleClass('showPwd1');
      $('.hidePwd').toggleClass('hidePwd1');
      return $('#loginpassword').attr('type', 'password');
   }
   
   Closepagealert(event) {
      event.preventDefault();
      $(".toast-error").html('');
      $(".toast-success").html('');
      $(".toast-info").html('');
      $(".toast-warning").html('');
      $(".toast-error").removeClass('toast');
      $(".toast-success").removeClass('toast');
      $(".toast-info").removeClass('toast');
      $(".toast-warning").removeClass('toast');
   }

   render() {
      return (
         <div style={{'height': window.innerHeight+'px', 'width': window.innerWidth+'px'}} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 LoginWrapper">
            <div className="col-12 mt-5">
               <div className="col-12 col-lg-2 rounded px-4 py-3 logoWrapper">
                  <img src="/images/Logo.png" className="logo" alt="logo"/>
               </div>
               <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5"> 
                  <img src="/images/periscopeLogo.png" className="periscopeLogo" alt="Periscope"/>                  
               </div>
               <div className="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-5 mt-5 text-center">
                  <div className="col-12 col-lg-10 offset-lg-1 loginCase">
                     <div className="col-12">
                        {/*<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 innloginwrap">
                           <h3>Sign In</h3>
                        </div>*/}
                        <form id="login" onSubmit={this.userlogin.bind(this)}>
                           <div className="form-group textAlignLeft col-lg-12 col-md-12 col-sm-12 col-xs-12 NOpadding mt25">
                              <label>Email ID</label><label className="astricsign">*</label>
                              <input type="email" className="form-control" onChange={this.handleChange} ref="loginusername" id="loginusername" name="loginusername" placeholder="Email ID" required />
                           </div>
                           <div className="textAlignLeft col-lg-12 col-md-12 col-sm-12 col-xs-12 NOpadding mb25">
                              <label>Password</label><label className="astricsign">*</label>
                              <input type="password" className="form-control" ref="loginpassword" name="loginpassword" id="loginpassword" placeholder="Password" required />
                              <div className="showHideSignDiv">
                                 <i className="fa fa-eye showPwd showEyeupSign" aria-hidden="true" onClick={this.showSignPass.bind(this)}></i>
                                 <i className="fa fa-eye-slash hidePwd hideEyeSignup " aria-hidden="true" onClick={this.hideSignPass.bind(this)}></i>
                              </div>
                           </div>

                           {
                              this.state.btnLoading
                              ?
                                 <div className="col-lg-3 col-lg-offset-4 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 NOpaddingRight ">
                                    <div align="center" className="cssload-fond">
                                       <div className="cssload-container-general">
                                          <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_1"> </div></div>
                                          <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_2"> </div></div>
                                          <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_3"> </div></div>
                                          <div className="cssload-internal"><div className="cssload-ballcolor cssload-ball_4"> </div></div>
                                       </div>
                                    </div>
                                 </div>
                              :
                                 <div className="col-lg-10 col-lg-offset-1 col-md-6 col-md-offset-3 col-sm-12 col-xs-12 NOpaddingRight">
                                    <input id="logInBtn" type="submit" className="col-12 btn loginBtn" value="SIGN IN" />
                                 </div>
                           }
                           <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt30 mb25">
                              <div className="row">
                                 <div className="textAlignCenter col-lg-12 col-md-12 col-sm-12 col-xs-12 mt10">
                                    <div className="row loginforgotpass">
                                       <a href='/forgotpassword' className="">Forgot Password?</a>
                                    </div>
                                 </div>                             
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>        
         </div>
       );
   }
}

const mapStateToProps = (state)=>{
   console.log("state = ",state)
   return {
      userDetails   : state.userDetails,
   }
};


const mapDispatchToProps = (dispatch)=>{
   return {
      setGlobalUser  : (userDetails)=> dispatch({
                          type      : "SET_GLOBAL_USER",
                          userDetails : userDetails,
                        }),
   }
};



export default Login;