import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import TemplateRow from './emails/TemplateRow.jsx';
import EmailTemplateRow from './emails/EmailTemplateRow.jsx';
import NotificationTemplateRow from './notifications/NotificationTemplateRow.jsx';
import AllNotificationTemplateRow from './notifications/AllNotificationTemplateRow.jsx';
import AllSMSTemplateRow from './sms/AllSMSTemplateRow.jsx';
import SMSTemplateRow from './sms/SMSTemplateRow.jsx';
import CKEditor from "react-ckeditor-component";
// import validator from 'validator';
import './notification.css';
import validate               from 'jquery-validation';

var NotificationEvents = process.env.REACT_APP_NotificationEvents;


// import NotificationEvents 		from 	"../../../notificationEvents.json";


class CreateTemplate extends Component {

	constructor(props) {

		super(props);
		this.state = {
			templateType: props.templateType ? props.templateType : "Email",
			templateName: props.templateName ? props.templateName : "",
			subject: props.subject ? props.subject : "",
			editorEmail: null,
			editorNotification: null,
			editorSMS: null,
			contentError: '',
			defaultLabel: ' --Select-- ',
			subjecterror: '',
			templateNameerror: '',
			templateTypeerror: '',
			emailTemplates: {},
			notificationTemplates: {},
			smsTemplates: {},
			selector: {},
			event:"",
			filterEvent:"",
			formerrors: {
				message: '',
				subject: '',

			},
			role:"",
			filterRole:"",
			roleArray:[],
			status:"active",
			filterStatus:"",
			company:"All",
			filterCompany:"All",
			companyArray:[],
			companyname:"",
			filteredEmailTemplatesCount : 0,
			filteredNotifTemplatesCount : 0,
			filteredSMSTemplatesCount : 0,
			emailTemplatesListCount : 0,
			notificationTemplatesListCount : 0,
			smsTemplatesListCount : 0,
			emailTemplatesList:[],
			notificationTemplatesList: [],
			smsTemplatesList: [],
			tabtype : 'emailTemplates',
			NotificationEvents : JSON.parse(NotificationEvents)
		};
		this.updateContent = this.updateContent.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeNotification = this.onChangeNotification.bind(this);
		this.onChangeSMS = this.onChangeSMS.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getEmailData = this.getEmailData.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.getNotiData = this.getNotiData.bind(this);
		this.getSmsData = this.getSmsData.bind(this);
	}

	handleChange(event) {

		const datatype = event.target.getAttribute('data-text');
		const { name, value } = event.target;
		let formerrors = this.state.formerrors;
		this.setState({
			formerrors,
			[name]: value
		});
	}

	componentDidMount() {

		console.log("1 NotificationEvents => ",this.state.NotificationEvents[0]);

        this.getRoles();
        this.getCompany();
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("token");
		$("html,body").scrollTop(0);
		this.getData();
		$.validator.addMethod("regxtemplateName", function (value, element, arg) {
			return arg !== value;
		}, "Please select the template Name ");
		$.validator.addMethod("regxEvent", function (value, element, arg) {
            return  arg !== value;
        }, "Please select the Event.");
        $.validator.addMethod("regxRole", function (value, element, arg) {
            return arg !== value;
        }, "Please select the Role.")
        $.validator.addMethod("regxStatus", function (value, element, arg) {
            return arg !== value;
        }, "Please select the Status.");
		/*$.validator.addMethod("regxtemplateType", function (value, element, arg) {
			return arg !== value;
		}, "Please select the template Type ");*/
		    $("#newTemplateForm").validate({
		      rules: {
		        event: {
                    required: true,
                    regxEvent: ""
                },
		         templateName: {
		          required: true,
		          regxtemplateName: ""
		        }, 
		        
		        subject: {
		          required: true,
		        }, 
		        role: {
                    required: true,
                    regxRole: ""
                },
                status: {
                    required: true,
                    regxStatus: "--Select Status--"
                },
		       /* editor: {
		          required: true,
		          regxeditor: this.state.editor
		        },*/      
      },
      errorPlacement: function(error, element) {
       /* if (element.attr("name") === "templateType"){
          error.insertAfter("#templateType");
        } */
        if (element.attr("name") === "event") {
                    error.insertAfter("#event");
                }
        if (element.attr("name") === "templateName"){
          error.insertAfter("#templateName");
        }
        if (element.attr("name") === "subject"){
          error.insertAfter("#subject");
        }
        if (element.attr("name") === "editorEmail"){
          error.insertAfter("#editorEmail");
        }
        if (element.attr("name") === "editorNotification"){
          error.insertAfter("#editorNotification");
        }
        if (element.attr("name") === "editorSMS"){
          error.insertAfter("#editorSMS");
        }
        if (element.attr("name") === "role") {
            error.insertAfter("#role");
        }
        if (element.attr("name") === "status") {
                    error.insertAfter("#status");
                }
      }
    });
	
	}

	componentWillReceiveProps(nextProps) {
		this.getData();
		this.getFilteredTemplate(this.state.selector);
	}
	getRoles() {
        var data = {
	      "startRange": 0,
	      "limitRange": 100000,
	    }
	    axios.post('/api/roles/get/list', data)
	      .then((response) => {
	        this.setState({
	          roleArray: response.data
	        }, () => {
	        })
	      }).catch(function (error) {
	      });
    }
    getCompany() {
	    // axios.get('/api/entitymaster/getAllEntities')
	    //   .then((response) => {
	    //     this.setState({
	    //       companyArray: response.data
	    //     }, () => {
	    //     })
	    //   }).catch(function (error) {
	    //   });
    }
	AllNotificationTemplates() {
		const id = this.state.currentNotificationId;
		var notificationTemplates = this.state.notificationTemplates;
		if (notificationTemplates && notificationTemplates.length > 0) {
			for (var i = 0; i < notificationTemplates.length; i++) {
				if (notificationTemplates[i]._id === id) {
					$('.defaultNotification').css({ 'display': 'none' });
					return [notificationTemplates[i]];
				}
			}
		} else {
			return [];
		}
		return [];
	}
	getData() {
		axios({
			method: 'get',
			url: '/api/masternotifications/get/list',
		}).then((response) => {
			var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
			var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
			var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
			this.setState({
				emailTemplatesList: emailTemplatesList,
				emailTemplatesListCount: emailTemplatesList.length,
				notificationTemplatesList: notificationTemplatesList,
				notificationTemplatesListCount: notificationTemplatesList.length,
				smsTemplatesList: smsTemplatesList,
				smsTemplatesListCount: smsTemplatesList.length
			});

		}).catch(function (error) {

		});
	}
	AllsmsTemplates() {
		const id = this.state.currentSMSId;
		var smsTemplates = this.state.smsTemplates;
		if (smsTemplates && smsTemplates.length > 0) {
			for (var i = 0; i < smsTemplates.length; i++) {
				if (smsTemplates[i]._id === id) {
					$('.defaultSMS').css({ 'display': 'none' });
					return [smsTemplates[i]];
				}
			}
		} else {
			return [];
		}
		return [];
	}

	getId(id){
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				emailTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})
	}
	getEmailData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					emailTemplates: response.data
				})
			});
		}
	}

	getNotiData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					notificationTemplates: response.data
				})
			});
		}
	}

	getSmsData(id) {
		if (id) {
			axios({
				method: 'get',
				// url: 'http://localhost:3048/api/masternotifications/'+id,
				url: '/api/masternotifications/get/' + id,
			}).then((response) => {
				this.setState({
					smsTemplates: response.data
				})
			});
		}
	}


	getNotificationId(id) {
		
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				notificationTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})

	}
	getSmsId(id) {
		
		axios.get('/api/masternotifications/get/'+id)
		.then((response) => {
			this.setState({
				smsTemplates: response.data
			})
			if(response.data.company === null){
				this.setState({
					companyname : 'All'
				})
			}else{
				var companyId = response.data.company ;
				axios.get('/api/entitymaster/get/one/'+companyId)
				.then((res)=>{
					this.setState({companyname : res.data[0].companyName})
				})
				.catch((error)=>{console.log(error)})
			}
		})
		.catch((error)=>{console.log(error)})
	}

	deleteData(type, id) {
		if (type && id) {
			if (type === "Email") {
				var emailarray = [...this.state.emailTemplatesList]; // make a separate copy of the array
				var index = emailarray.findIndex((obj) => { return obj._id === id });
				if (index !== -1) {
					emailarray.splice(index, 1);
					this.setState({ emailTemplatesList: emailarray, emailTemplates: {} }, () =>
						this.getData());
				}
			} else if (type === "Notification") {
				var notificationarray = [...this.state.notificationTemplatesList]; // make a separate copy of the array
				var notificationindex = notificationarray.findIndex((obj) => { return obj._id === id });
				if (notificationindex !== -1) {
					notificationarray.splice(notificationindex, 1);
					this.setState({ notificationTemplatesList: notificationarray, notificationTemplates: {} });
				}
			} else if (type === "SMS") {
				var smsarray = [...this.state.smsTemplatesList]; // make a separate copy of the array
				var smsindex = smsarray.findIndex((obj) => { return obj._id === id });
				if (smsindex !== -1) {
					smsarray.splice(smsindex, 1);
					this.setState({ smsTemplatesList: smsarray, smsTemplates: {} });
				}
			}
		}
	}

	selectType(type,event) {
		const target = event.target;
		const name = target.name;
		this.setState({
			templateType: type,
		})

	}
	updateContent(newContent) {
		this.setState({
			editorEmail: newContent,
			editorNotification: newContent,
			editorSMS: newContent
		})
	}
	onChangeEmail(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorEmail: newContent
		}, () => {
			if (this.state.editorEmail) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}
	onChangeNotification(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorNotification: newContent
		}, () => {
			if (this.state.editorNotification) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}
	onChangeSMS(evt) {
		var newContent = evt.editor.getData();
		this.setState({
			editorSMS: newContent
		}, () => {
			if (this.state.editorSMS) {
				this.setState({
					contentError: ''
				});
			} else {
				this.setState({
					contentError: ''
				})
			}
		})
	}

	submitTemplate(event) {
		event.preventDefault();
		var event = this.state.event;
		var role = this.state.role;
		var company = this.state.company;
		var status = this.state.status;
		var templateType = this.state.templateType;
		var subject = this.state.subject;
		var emailContent = this.state.editorEmail;
		var notificationContent = this.state.editorNotification;
		var smsContent = this.state.editorSMS;
		if(company === 'All'){
			company = null
		}
		if(event && role){
			if(templateType === 'Email'){
				if(subject === "" & emailContent === null || emailContent === ""){
					swal("For Email Template Subject & Message are mandatory")
							// $('#createNotifyModal').hide();
							// $('.modal-backdrop').remove();
				}else{
					
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"subject": subject,
						"content": emailContent,
					}
					axios.post('/api/masternotifications/post', formValues)
				  	.then(function (response) {
				    // handle success
				    	if(response.data.message === "Notification Details already exists"){
				    		swal("Email Template already exists")
				    		
				    	}else{				    		
					    	swal({
								title: "Email Template added successfully",		
							});							
						}
				    	this.props.history.push('/ViewTemplates')
                		window.location.reload();
				  	})
				  	.catch(function (error) {
				    // handle error
				    	console.log(error);
				  	});
					// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						

						this.setState({
									subject: "",
									editorEmail: null
								});
					}).catch(function (error) {
					});
				}
			}else if(templateType === 'Notification'){
				if(notificationContent === null || notificationContent === ""){
					swal("Please enter message")
				}else{
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"content": notificationContent,
					}
					axios.post('/api/masternotifications/post', formValues)
				  	.then(function (response) {
				    // handle success
				    	if(response.data.message === "Notification Details already exists"){
				    		swal("Notification Template already exists")
				    		
				    	}else{
				    		
					    	swal({
								title: "Notification template added successfully",		
							});
						}
				    	 // window.location.reload();
				  	})
				  	.catch(function (error) {
				    // handle error
				    	console.log(error);
				  	});
					// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						
						this.setState({
									editorNotification: null
								});
						
					}).catch(function (error) {});
				}
			}else{
				if(smsContent === null || smsContent === ""){
					swal("Please enter message")
				}else{
					var formValues = {
						"event":event,
						"templateType": templateType,
						"role": role,
						"status":status,
						"company":company,
						"content": smsContent,
					}
					axios.post('/api/masternotifications/post', formValues)
				  	.then(function (response) {
				    // handle success
				    	if(response.data.message === "Notification Details already exists"){
				    		swal("SMS Template already exists")
				    		
				    	}else{
				    		
					    	swal({
								title: "SMS template added successfully",		
							});
						}
				    	 // window.location.reload();
				  	})
				  	.catch(function (error) {
				    // handle error
				    	console.log(error);
				  	});
					// console.log("im in sucess message");
					
					axios({
						method: 'get',
						url: '/api/masternotifications/get/list',
					}).then((response) => {
						var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
						var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
						var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
						this.setState({
							emailTemplatesList: emailTemplatesList,
							emailTemplatesListCount: emailTemplatesList.length,
							notificationTemplatesList: notificationTemplatesList,
							notificationTemplatesListCount: notificationTemplatesList.length,
							smsTemplatesList: smsTemplatesList,
							smsTemplatesListCount: smsTemplatesList.length
						});
						
						this.setState({
									editorSMS: null
								});
						
					}).catch(function (error) {});
				}
			}
		}else{
			swal("Please select Event & Role")
		}
		
	}

	closeModal(event){
		event.preventDefault();
		$('#createNotifyModal').hide();
    	$(".modal-backdrop").remove();
    	 window.location.reload();
	}


	getInfo(event){
		event.preventDefault();
		window.open('/NotificationVariableList','_blank');
	}

	showToken(event){
		$('.showTokens').toggle();
	}

	selectFilter(event){
		$(".filterWrapper").toggle();
	}

	resetFilter(event) {
		event.preventDefault();
		this.setState({
			filterStatus:'',
			filterEvent:'',
			filterRole:'',
			filterCompany:'All',
			filteredEmailTemplatesCount:0,
			filteredNotifTemplatesCount:0,
			filteredSMSTemplatesCount:0,
			emailTemplates: {},
			notificationTemplates: {},
			smsTemplates: {},
			selector:{}
		},()=>{
			axios({
				method: 'get',
				url: '/api/masternotifications/get/list',
			}).then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					emailTemplatesListCount: emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					notificationTemplatesListCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					smsTemplatesListCount: smsTemplatesList.length
				});
				
				this.setState({
							editorSMS: null
						});
				
			}).catch(function (error) {});
		})
	}

	onSelectedItemsChange(filterType, selecteditems){
		var selector=this.state.selector;
		this.setState({
	      [selecteditems.currentTarget.getAttribute('name')]: selecteditems.currentTarget.value
	    });
		
		if(filterType === 'filterEvent'){
			selector.filterEvent  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterRole'){
			selector.filterRole  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterStatus'){
			selector.filterStatus  = selecteditems.currentTarget.value; 
		}
		if(filterType === 'filterCompany'){
			selector.filterCompany  = selecteditems.currentTarget.value; 
		}
		this.setState({	selector: selector },()=>{
			this.getFilteredTemplate(this.state.selector);
		})
	}
	getFilteredTemplate(selector){
		axios.post("/api/masternotifications/get/filterTemplate", selector)
			.then((response) => {
				var emailTemplatesList = response.data.filter((a) => { return a.templateType === "Email" });
				var notificationTemplatesList = response.data.filter((a) => { return a.templateType === "Notification" });
				var smsTemplatesList = response.data.filter((a) => { return a.templateType === "SMS" });
				this.setState({
					emailTemplatesList: emailTemplatesList,
					filteredEmailTemplatesCount:emailTemplatesList.length,
					notificationTemplatesList: notificationTemplatesList,
					filteredNotifTemplatesCount: notificationTemplatesList.length,
					smsTemplatesList: smsTemplatesList,
					filteredSMSTemplatesCount:smsTemplatesList.length
				});
				
				this.setState({
					editorSMS: null,
					emailTemplates: {},
					notificationTemplates: {},
					smsTemplates: {},
				});
				$('.defaultMsg').css({'display':'block'});
				$('.defaultNotification').css({'display':'block'});
				$('.defaultSMS').css({'display':'block'});
			})
			.catch((error) => {
			})
	}

	changeTab = (data)=>{
      this.setState({
        tabtype : data,
      })
    }

    showModal(event){
    	event.preventDefault();
    	$('#createNotifyModal').show()

    }


	render() {
		const {formerrors} = this.state;
		return (
			<div className="container-fluid">
				<div className="row">
					<section className="content">
						<div className="col-12 pageContent">
							<div className="row">

								<div className="col-12 py-0 mb-5">
									<div className="col-12 py-4 pageHeader mb-2">
										Create Notification Template
									</div>
									<hr className="mt-0 hr-head container-fluid row" />
								</div>


								<section className="col-12">
									<div className="row">
										<div className="col-3 ml-5">
											<label>Event <sup className="astrick">*</sup></label>
                               	<select id="event" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" value={this.state.event} ref="event" name="event" onChange={this.handleChange.bind(this)}>
                                   	<option disabled value="">--Select Event--</option>
                                   	{
                                   		this.state.NotificationEvents && this.state.NotificationEvents.length > 0
                                   		?
	                                   		this.state.NotificationEvents.map((event,index)=>{
	                                   			return(
	                                   				<option value={event} key={index}> {event} </option>
	                                   			)

	                                   		})
	                                   	:
	                                   		swal("Please Provide Notification Events in .env file as REACT_APP_NotificationEvents array!")
                                   	}
                               	</select>   
										</div>

										<div className="col-3">
											<label>Role<sup className="astrick">*</sup></label>
                                              <select id="role" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" value={this.state.role} ref="role" name="role" onChange={this.handleChange.bind(this)}>
                                                  <option disabled value="">--Select Role--</option>
                                                  {
                                                      this.state.roleArray && this.state.roleArray.length > 0 ?
                                                          this.state.roleArray.map((data, i)=>{
                                                              return(
                                                                  <option key={i} value={data.role}>{data.role} </option>
                                                              );
                                                          })
                                                      :
                                                      null
                                                  }
                                              </select>
										</div>

										<div className="col-3">
											<label className="labelform col-lg-12 col-md-12 col-sm-12 col-xs-12">Status<sup className="astrick">*</sup></label>
                                 <select id="status" className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" value={this.state.status} ref="status" name="status" onChange={this.handleChange.bind(this)}>
                                    <option disabled value="">--Select Status--</option>
                                    <option> active </option>
												<option> inactive </option>
                                 </select>
										</div>
									</div>

									<div className="ml-4 my-5 col-12">
										<ul className="nav nav-pills my-5">
										  	<li className="nav-item defaultColor mr-5"><a className="nav-link px-5 active" data-toggle="pill" href="#email" onClick={()=>{this.setState({templateType : "Email"})}}>Email</a></li>
										  	<li className="nav-item defaultColor "><a className="nav-link px-5" data-toggle="pill" href="#sms" onClick={()=>{this.setState({templateType : "SMS"})}}>SMS</a></li>
										</ul>

										<div className="tab-content px-0">
											<div id="email" className="tab-pane fade in active">
												<div className="col-12">
													<label>Subject <span className="astrick">*</span></label>
													<input type="text" name="subject" data-text="subject"  id="subject" value={this.state.subject} onChange={this.handleChange.bind(this)} className="subject form-control col-12 inputValid" required />
												</div>

												<div className="col-12 mt-5">
													<label>Message <span className="astrick">*</span></label>
													<div className="" id="editorEmail">
														<CKEditor activeclassName="p15"  name="editorEmail" data-text="message" className="editorEmail" content={this.state.editorEmail} events={{ "change": this.onChangeEmail }}/>
													</div>
												</div>
										  	</div>

										  	<div id="sms" className="tab-pane fade in">
												<div className="form-group">
													<label>Message <span className="astrick">*</span></label>
													<div className="col-12" id="editorSMS">
														<CKEditor activeclassName="p15"  name="editorSMS" data-text="message" className="editorSMS" content={this.state.editorSMS} events={{ "change": this.onChangeSMS }}/>
													</div>
												</div>
										  	</div>
										</div>
									</div>

									<form className="newTemplateForm mb-5" id="newTemplateForm">
										<div className="row">
											<button onClick={this.submitTemplate.bind(this)} className="col-12 col-md-6 offset-md-5 col-lg-3 offset-lg-8 btn btn-primary button3 outlinebox">
												Save Template
											</button>
										</div>
									</form>
								</section>
							</div>
						</div>
					</section>
				</div>
			</div>

		);
	}
}
export default CreateTemplate;