import React, { Component } from 'react';
import { connect }        from 'react-redux';
import { withRouter }       from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


import Header from '../common/header/Header.js'
import ViewAllNotification from '../common/header/ViewAllNotifications.js'
import Footer from '../common/footer/Footer.js'
import Dashboard from '../dashboard/Dashboard.js'
import ViewTemplates    from '../NotificationManagement/ViewTemplates.jsx';
import EventMapping     from '../NotificationManagement/EventMapping.js';
import NotificationVariableList from '../NotificationManagement/NotificationVariableList.js';
import EventToken from '../NotificationManagement/EventToken.js';

import Leftsidebar from '../common/leftSidebar/Leftsidebar.js';
import UMListOfUsers from '../userManagement/UM/UMListOfUsers.js';
import DeletedUsers from '../userManagement/UM/DeletedUsers.js';
import EditUserProfile  from '../userManagement/UM/EditUserProfile.js';
import UserProfile      from '../userManagement/UM/UserProfile.js';
import UMRolesList      from '../userManagement/Roles/Roles.js';
import UMRolesEntity    from '../userManagement/Roles/RolesEntity.js';




class CoreLayout extends Component {
    render() {

        return (
            <Switch >
               
                <Route path="/" component={Dashboard} exact />
                <Route path="/dashboard" component={Dashboard} exact />
                <Route path="/umlistofusers" component={UMListOfUsers} exact />
                <Route path="/DeletedUsers" component={DeletedUsers} exact />
                <Route path="/umroleslist" component={UMRolesList}strict exact />
                <Route path="/umroleslist/:fieldID" exact strict component={UMRolesList} />
                <Route path="/umroleslist/oneField/:fieldID" exact strict component={UMRolesList} />              
                <Route path="/edituserprofile/:id" component={EditUserProfile} exact />
                <Route path="/profile/:id" component={UserProfile} exact />
                <Route path="/ViewTemplates" component={ViewTemplates} exact />

                <Route path="/EventMapping" component={EventMapping} exact />
                <Route path="/NotificationVariableList" component={NotificationVariableList} exact />
                <Route path="/ViewAllNotification" component={ViewAllNotification} exact />
                <Route path="/EventToken" component={EventToken} exact />
                               
            </Switch>
        );
    }
}




const mapStateToProps = (state)=>{
    // console.log(" state on Dashboard corporate==> ",state)
    return {
      userDetails   : state.userDetails,
    }
  };
  
  
  const mapDispatchToProps = (dispatch)=>{
    return {
    }
  };
  
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoreLayout));

