
import React, { Component } from 'react';
import swal from 'sweetalert';
import 'font-awesome/css/font-awesome.min.css';
import './SignUp.css';
import axios from 'axios';

class ResetPassword extends Component {
   constructor() {
      super();
      this.state = {
         'newpassword': "",
         'oldpassword': "",
         'confirmPassword': "",

         errors: {
            'newpassword': "",
            'oldpassword': "",
            'confirmPassword': "",
         }
      }
      this.changepassword = this.changepassword.bind(this);
   }

   handleChange = (event) => {
      event.preventDefault();
      var name = event.target.name;
      this.setState({
         [name]: event.target.value
      })
   }

   componentDidMount() {
      var userDetails = localStorage.getItem("userDetails");
      var userDetailsParse = JSON.parse(userDetails);
      // console.log("userDetailsParse => ",userDetailsParse);

      this.setState({
         user_id: userDetailsParse.user_id,
         username: userDetailsParse.email,
         email: userDetailsParse.email,
      })
   }

   showSignPass(event) {
      var fullId = event.currentTarget.id;
      var idArr = fullId.split("-");
      var id = idArr[0];

      if (fullId.substr(-1) === '1') {
         var otherFullId = fullId.substr(0, fullId.length - 1) + '2';
      } else {
         var otherFullId = fullId.substr(0, fullId.length - 1) + '1';
      }

      if (document.getElementById(fullId).classList.contains("showPwd")) {
         document.getElementById(fullId).classList.remove("showPwd");
         document.getElementById(fullId).classList.add("hidePwd");
         document.getElementById(otherFullId).classList.remove("hidePwd");
         document.getElementById(otherFullId).classList.add("showPwd");
      } else {
         document.getElementById(fullId).classList.remove("hidePwd");
         document.getElementById(fullId).classList.add("showPwd");
         document.getElementById(otherFullId).classList.remove("showPwd");
         document.getElementById(otherFullId).classList.add("hidePwd");
      }

      // console.log("document.getElementById(id).type => ", document.getElementById(id).type);

      if (document.getElementById(id).type === "password") {
         document.getElementById(id).type = "text";
      } else {
         document.getElementById(id).type = "password";
      }

   }

   validateForm() {
      let errors = {};
      let formIsValid = true;

      if (!this.state.oldpassword) {
         formIsValid = false;
         errors["oldpassword"] = "This field is required.";
      }

      if (!this.state.newpassword) {
         formIsValid = false;
         errors["newpassword"] = "This field is required.";
      }
      if (typeof this.state.newpassword !== "undefined") {
         if (this.state.newpassword.length >= 6) {
            var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if (!re.test(this.state.newpassword)) {
               formIsValid = false;
               errors["newpassword"] = "Must have min 1 Upper & 1 Lower Case letter, 1 Number & 1 Symbol"
            }
         } else {
            formIsValid = false;
            errors["newpassword"] = "Min 6 characters required";
         }
      }


      if (!this.state.confirmPassword) {
         formIsValid = false;
         errors["confirmPassword"] = "This field is required.";
      }

      if (this.state.newpassword !== this.state.confirmPassword) {
         errors["confirmPassword"] = "New Password and Confirm Password are equal";
      }


      this.setState({
         errors: errors
      }, () => {
         if (Object.keys(errors).length !== 0) {
            new swal("Validation Error", "One of the field needs some correction!", "error");
         }
         console.log("this.state.errors = ", this.state.errors);
      });

      return formIsValid;
   }


   changepassword(event) {
      event.preventDefault();

      if (this.validateForm()) {
         var user_id = this.state.user_id;
         var formValues = {
            user_id: user_id,
            oldPassword: this.state.oldpassword,
            newPassword: this.state.newpassword,
         }
         // console.log("1 formValues => ",formValues);

         axios
            .patch('/api/auth/patch/reset_password', formValues)
            .then(response => {
               // console.log("1 response => ",response);
               if (response.status === 200) {
                  swal(response.data.message, "", 'success');
               } else {
                  swal(response.data.message, "", 'error');
               }

            })
            .catch(error => {
               console.log("ERROR in Responce", error);
               swal("Invalid Password", "Please Enter correct password", "error");
               this.setState({ invalidpassword: true })
            })
      }
   }

   resetPassword(event) {
      event.preventDefault();

      if (this.validateForm()) {
         var formValues = {
            user_id: this.state.user_id,
            userName: this.state.username,
            pwd: this.state.newpassword,
            currentPassword: this.state.oldpassword
         };
         axios.patch('/api/auth/patch/change-password-after-login', formValues)
            .then((response) => {
               // console.log("change_password_using_otp => ",response)
               if (response.data.message === "Password Changed successfully!") {
                  this.setState({
                     "showMessage": true,
                     oldpassword: "",
                     newpassword: "",
                     confirmPassword: "",
                  })
                  new swal("Password changed successfully!", "Next time, use this new password to Login!", "success");
               } else {
                  new swal("Something went wrong!", response.data.message, "error");
               }
            })
            .catch((error) => {
               console.log("reset Password error=", error);
            });
      }
   }



   render() {
      return (
         <div className="col-12 pageContent">
            <div className="col-12 pt-4 pageHeader">
               Reset Password
            </div>
            <hr className="hr-head mt-0 mb-5 container-fluid row" />
            <br />
            <form id="resetPassword" className="mt-5">
               <div className="form-group loginFormGroup pdleftclr veribtm float-none col-lg-6 col-sm-12  mx-auto mt-5 mb-5">
                  <div className="input-group">
                     <input type="password" className="form-control loginInputs inputTextPass" id="oldpassword" name="oldpassword" value={this.state.oldpassword} onChange={this.handleChange} placeholder="Old Password" aria-label="oldpassword" aria-describedby="basic-addon1" title="Password should be at least 6 characters long!" pattern=".{6,}" required />
                     <span className="input-group-addon addons glyphi-custommm padBoth" id="basic-addon1">
                        <i className="fa fa-eye Pass showPwd" aria-hidden="true" id="oldpassword-icon1" onClick={this.showSignPass.bind(this)}></i>
                        <i className="fa fa-eye-slash Pass hidePwd" aria-hidden="true" id="oldpassword-icon2" onClick={this.showSignPass.bind(this)}></i>
                     </span>
                  </div>
                  <span className="errorMsg"> {this.state.errors.oldpassword} </span>
               </div>

               <div className="form-group loginFormGroup pdleftclr veribtm float-none col-lg-6 col-sm-12  mx-auto mt-5">
                  <div className="input-group">
                     <input type="password" className="form-control loginInputs inputTextPass" id="newpassword" name="newpassword" value={this.state.newpassword} onChange={this.handleChange} placeholder="New Password" aria-label="Password" aria-describedby="basic-addon1" title="Password should be at least 6 characters long!" pattern=".{6,}" required />
                     <span className="input-group-addon addons glyphi-custommm padBoth" id="basic-addon1">
                        <i className="fa fa-eye Pass showPwd" aria-hidden="true" id="newpassword-icon1" onClick={this.showSignPass.bind(this)}></i>
                        <i className="fa fa-eye-slash Pass hidePwd" aria-hidden="true" id="newpassword-icon2" onClick={this.showSignPass.bind(this)}></i>
                     </span>
                  </div>
                  <span className="errorMsg"> {this.state.errors.newpassword} </span>
               </div>

               <div className="form-group loginFormGroup pdleftclr veribtm float-none col-lg-6 col-sm-12  mx-auto mt-5">
                  <div className="input-group">
                     <input type="password" className="form-control loginInputs inputTextPass" id="confirmPassword" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} placeholder="Confirm Password" aria-label="confirmPassword" aria-describedby="basic-addon1" title="Password should be at least 6 characters long!" pattern=".{6,}" required />
                     <span className="input-group-addon addons glyphi-custommm padBoth" id="basic-addon1">
                        <i className="fa fa-eye Pass showPwd" aria-hidden="true" id="confirmPassword-icon1" onClick={this.showSignPass.bind(this)}></i>
                        <i className="fa fa-eye-slash Pass hidePwd" aria-hidden="true" id="confirmPassword-icon2" onClick={this.showSignPass.bind(this)}></i>
                     </span>
                  </div>
                  <span className="errorMsg"> {this.state.errors.confirmPassword} </span>
               </div>

               <div className="submitButtonWrapper pdleftclr col-6 mx-auto">
                  <button type="submit" onClick={this.resetPassword.bind(this)} className="col-12 col-md-4 my-5 btn resetBtn pull-right">
                     Reset Password
                  </button>
               </div>
            </form>
         </div>
      );
   }
}
export default ResetPassword;