import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import moment from "moment";
import swal from "sweetalert";

const SessionTimeout = () => {
  const events = ["click", "load", "scroll"];
  const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  var userDetails = localStorage.getItem("userDetails");
  const isAuthenticated = userDetails ? JSON.parse(userDetails) : false;

  const logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 5000);
  };

  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const sessionTime = process.env.REACT_APP_SESSION_MAX_TIME ?? 2;
      const maxTime = sessionTime > 1 ? sessionTime : 2;
      const popTime = maxTime - 1;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 60 - diff.seconds();
      if (minPast == popTime) {
        setSecond(leftSecond);
        setOpen(true);
      }
      if (minPast == maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem("lastTimeStamp");
        logout();
      }
    }, 1000);
  };

  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated?.token) {
      timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
    setOpen(false);
  }, [isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  if (isOpen) {
    swal(
      "Session Timeout",
      `Session will expire in ${second} seconds.
      Click on OK to continue your session.`,
      "warning"
    );
  }

  return <Fragment />;
};

export default SessionTimeout;
