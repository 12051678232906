import React,{Component}                      from 'react';
import {BrowserRouter as Router, Route,Link } from 'react-router-dom';
import $                                      from "jquery";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Rightsidebar.css';

export default class Rightsidebar extends Component{
  
  constructor(props) {
   super(props);
    this.state = {}
  }
 
  render(){
    return(
      <Router>
        <div>
          <aside className="leftsidebar">
            <div className="">
              <nav id="sidebar1">       
                <ul className="list-unstyled components">
                  <li className="active">
                    <div className="rightsideHeading ">
                      Admin Activities
                    </div>
                  </li>
                  <li className="sidebarMenuText">
                     <a href="/create-user"  title="Create User">
                        <i className="glyphicon fa fa-user greenColor"></i> 
                        Create User
                     </a>
                  </li>                  
                  <li className="sidebarMenuText">
                     <a href="/user-management"  title="User Management">
                        <i className="glyphicon fa fa-users greenColor"></i> 
                        User Management
                     </a>
                  </li>                  
                  <li className="sidebarMenuText">
                     <a href="/ViewTemplates" title="Management">
                        <i className="fa fa-envelope yellowColor" />
                        Template Management
                     </a>
                  </li>
                  
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      </Router>
    );
  }
}
