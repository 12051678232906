import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
// import { connect }                                from 'react-redux';
// import { withRouter }                             from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';


// Section: 1 - SystemSecurity ******************************************************
import Login from './strive/systemSecurity/Login.js';
import ConfirmOtp from './strive/systemSecurity/ConfirmOtp.js';
import ForgotPassword from './strive/systemSecurity/ForgotPassword.js';
import ResetPassword from './strive/systemSecurity/ResetPassword.js';
import ResetPasswordInternal from './strive/systemSecurity/ResetPwd.js';
import Header from './strive/common/header/Header.js';
import Footer from './strive/common/footer/Footer.js';
import Leftsidebar from './strive/common/leftSidebar/Leftsidebar.js';

import CoreLayout from './strive/CoreLayout/CoreLayout1.js';
import Dashboard from './strive/dashboard/Dashboard.js'
// import UMListOfUsers       from  './strive/userManagement/UM/UMListOfUsers.js';
// import OrderList           from  './strive/orders/orderList.js';
import DeletedUsers from './strive/userManagement/UM/DeletedUsers.js';
import EditUserProfile from './strive/userManagement/UM/EditUserProfile.js';
import UserProfile from './strive/userManagement/UM/UserProfile.js';
import UMRolesList from './strive/userManagement/Roles/Roles.js';
import UMRolesEntity from './strive/userManagement/Roles/RolesEntity.js';
import UMListOfUsers from './strive/userManagement/UM/UMListOfUsers.js';
import CreateUser from './strive/userManagement/UM/CreateUser.js';

import ViewAllNotification from './strive/common/header/ViewAllNotifications.js';
import ViewTemplates from './strive/NotificationManagement/ViewTemplates.jsx';
import CreateTemplate from './strive/NotificationManagement/CreateTemplate.jsx';
import EditTemplate from './strive/NotificationManagement/EditTemplate.jsx';
import EventMapping from './strive/NotificationManagement/EventMapping.js';
import NotificationVariable from './strive/NotificationManagement/NotificationVariableList.js';
import EventToken from './strive/NotificationManagement/EventToken.js';

import OrderList from './strive/orders/orderList.js';
import OrderDetails from './strive/orders/orderDetails';
import MyPreference from './strive/preference/MyPreference.js'
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

const Layout = () => {
   return (
      <Router>
         <Switch>
            {
               [
                  { path: "/user-management", component: UMListOfUsers, extact: true, isPrivate: true },
                  { path: "/create-user", component: CreateUser, extact: true, isPrivate: true },
                  { path: "/DeletedUsers", component: DeletedUsers, extact: true, isPrivate: true },
                  { path: "/umroleslist", component: UMRolesList, strict: true, extact: true, isPrivate: true },
                  { path: "/umroleslist/:fieldID", extact: true, strict: true, component: UMRolesList, isPrivate: true },
                  { path: "/umroleslist/oneField/:fieldID", extact: true, strict: true, component: UMRolesList, isPrivate: true },
                  { path: "/dashboard", component: Dashboard, extact: true, isPrivate: true },
                  { path: "/edituserprofile/:id", component: EditUserProfile, extact: true, isPrivate: true },
                  { path: "/profile/:id", component: UserProfile, extact: true, isPrivate: true },
                  { path: "/ViewTemplates", component: ViewTemplates, extact: true, isPrivate: true },
                  { path: "/CreateTemplate", component: CreateTemplate, extact: true, isPrivate: true },
                  { path: "/edit-notification-template/:type/:template_id", component: EditTemplate, extact: true, isPrivate: true },
                  { path: "/EventMapping", component: EventMapping, extact: true, isPrivate: true },
                  { path: "/NotificationVariableList", component: NotificationVariable, extact: true, isPrivate: true },
                  { path: "/ViewAllNotification", component: ViewAllNotification, extact: true, isPrivate: true },
                  { path: "/EventToken", component: EventToken, extact: true, isPrivate: true },
                  { path: "/order-list", component: OrderList, extact: true, isPrivate: true },
                  { path: "/order-details/:id", component: OrderDetails, extact: true, isPrivate: true },
                  { path: "/my-preferences", component: MyPreference, extact: true, isPrivate: true },
                  { path: "/reset-pwd/:user_ID", extact: true, strict: true, component: ResetPasswordInternal, isPrivate: true },
                  { path: "/reset-pwd-admin/:user_ID", extact: true, strict: true, component: ResetPassword, isPrivate: true },
                  { path: "/login", extact: true, strict: true, component: Login, isPrivate: false },
                  { path: "/forgotpassword", extact: true, strict: true, component: ForgotPassword, isPrivate: false },
                  { path: "/confirm-otp/:userID", extact: true, strict: true, component: ConfirmOtp, isPrivate: false },
                  { path: "/reset-pwd/:user_ID", extact: true, strict: true, component: ResetPassword, isPrivate: false },
                  { path: "/user-management", component: UMListOfUsers, extact: true, isPrivate: false },
                  { path: "/",  component: OrderList, isPrivate: true },

               ].map((route, index) => route.isPrivate ?
                  <PrivateRoute key={index} {...route} /> : <Route key={index} {...route} />)
            }
         </Switch>
      </Router>
   );

}


const PrivateRoute = ({ component: Component, ...rest }) => {
   const userDetails = JSON.parse(localStorage.getItem("userDetails"));
   return (
      <div className="hold-transition skin-blue fixed sidebar-mini">
         <div className="wrapper">
            <Header />
            <div>
               <Leftsidebar />
               <div className="main-page-wrapper" id="main-page-wrapper">

                  <Route {...rest} render={props => (
                     userDetails?.token!=undefined?
                        <Component {...props} />
                        : <Redirect to={{
                           pathname:'/login',
                           state:{redirectUrl:window.location.pathname}
                           
                        }} />
                  )} />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}

export default Layout;