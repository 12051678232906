import React, { Component } from 'react';
import { connect }        from 'react-redux';
import { withRouter }       from 'react-router-dom';

import 'bootstrap/js/tab.js';
class Dashboard extends Component{
	constructor(props) {
		super(props);
		this.state = {
			"user_ID"   : "",
			"startRange": 0,
			"limitRange": 10,
			"editId"    :  '',
			"fieldValue" : ""
		};
	}

	componentDidMount(){
		// var userDetails = (localStorage.getItem('userDetails'));
		// var userData = JSON.parse(userDetails);
		console.log("*** Dashboard ***")
	}

	componentWillReceiveProps(nextprops){
	}

	render(){
		return (
			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
				<section className="content">
					<div className="pageContent col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{"backgroundImage" : `url("/images/Dashboard1.png")`, "backgroundSize": "100% 100%"}}>
						Jay Shri Ram
					</div>
				</section>
			</div>
		);
	}
}




const mapStateToProps = (state)=>{
  	console.log(" state on Dashboard ==> ",state)
  	return {
		userDetails   : state.userDetails,
  	}
};


const mapDispatchToProps = (dispatch)=>{
  return {
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));